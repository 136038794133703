<div>

    <div>
      <form [formGroup]="userform" >

                <mat-form-field>
                  <input type="hidden" formControlName="index">
                    <input matInput type="text" formControlName="firstname" placeholder="{{'pages.suscribeToInsurance.firstName' | translate}}" required>
                </mat-form-field>

              <mat-form-field >
                <input matInput type="text" formControlName="lastname" placeholder="{{'pages.suscribeToInsurance.lastName' | translate}}" required>
              </mat-form-field>

            <mat-form-field *ngIf="items?.email">
              <input matInput type="email" formControlName="email" placeholder="{{'pages.suscribeToInsurance.email' | translate}}" required>
            </mat-form-field>

            <mat-form-field *ngIf="items?.phone">
                <input matInput type="number" formControlName="phone" placeholder="{{'pages.suscribeToInsurance.phoneNumber' | translate}}" minlength="9" maxlength="12" required>
              </mat-form-field>

              <mat-form-field class="example-full-width">

                <mat-label>{{'pages.suscribeToInsurance.gender' | translate}}</mat-label>
                <mat-select required name="genre" formControlName="gender">
                  <mat-option *ngFor="let gr of gender" [value]="gr.value">
                    {{gr.value | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="example-full-width" *ngIf="items?.relationship!==null">
                <mat-label>{{'pages.suscribeToInsurance.relation' | translate}}</mat-label>
                <mat-select required name="relation" formControlName="relationship">
                  <mat-option *ngFor="let gr of RELATION" [value]="gr.value">
                    {{gr.value | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field class="example-full-width">
                <mat-label>Situation matrimoniale</mat-label>
                <mat-select required name="matimonial" formControlName="matrimonial">
                  <mat-option  value="Marié(e)">
                    Marié(e)
                   </mat-option>
                   <mat-option value="Célibataire">
                     Célibataire
                    </mat-option>
                </mat-select>
              </mat-form-field> -->
              <mat-form-field class="example-full-width" >
                <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="{{'pages.suscribeToInsurance.birthDate' | translate}}" formControlName="birthday" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field *ngIf="items?.address">
                <input matInput type="text" formControlName="address" placeholder="{{'pages.suscribeToInsurance.address' | translate}}" required>
            </mat-form-field>
            <!-- données médicales -->
            <!-- <mat-form-field class="example-full-width">

                <mat-label>Groupe sanguin</mat-label>
                <mat-select required name="genre" formControlName="g_sanguin">

                  <mat-option value="A+">A+</mat-option>
                  <mat-option value="A-">A-</mat-option>
                  <mat-option value="B+">B+</mat-option>
                  <mat-option value="B-">B-</mat-option>
                  <mat-option value="AB+">AB+</mat-option>
                  <mat-option value="AB-">AB-</mat-option>
                  <mat-option value="O+">O+</mat-option>
                  <mat-option value="O-">O-</mat-option>
                </mat-select>

              </mat-form-field>

              <div class="example-full-width " >

                <mat-radio-group aria-label="Select an option" formControlName="tensionCtrl">
                  <mat-label class="mr-2">Tension artérielle : </mat-label>
                  <mat-radio-button  class="mr-2" value="Hypo" class="md-primary">Hypo</mat-radio-button>
                  <mat-radio-button  class="mr-2" value="Hyper" class="md-primary">Hyper</mat-radio-button>
                  <mat-radio-button  class="ml-2" value="Normale"> Normale </mat-radio-button>
                </mat-radio-group>
              </div>

              <mat-radio-group aria-label="Select an option" class="example-full-width " name="diabete" formControlName="diabeteCtrl" >
                <mat-label class="mr-2">Diabétique : </mat-label>
                <mat-radio-button  class="mr-2" value="Oui" class="md-primary">Oui</mat-radio-button>
                <mat-radio-button  class="mr-2" value="Non" class="md-primary">Non</mat-radio-button>

              </mat-radio-group>

              <mat-form-field class="example-full-width mt-2">
                <mat-label>Hepatie</mat-label>
                <mat-select required name="hepatite" formControlName="hepatie">
                  <mat-option value="A">A</mat-option>
                  <mat-option value="B">B</mat-option>
                  <mat-option value="C">C</mat-option>
                  <mat-option value="D">D</mat-option>
                  <mat-option value="E">E</mat-option>
                  <mat-option value="Aucun">Aucun</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" formControlName="other_maladie" placeholder="Autres maladies">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" formControlName="allergie" placeholder="Allergie" >
            </mat-form-field> -->
        <div class="btn-sec">
          <button mat-raised-button color="warn" class="mr-3" mat-dialog-close>{{'pages.suscribeToInsurance.cancelButton' | translate}}</button>
          <button mat-raised-button color="primary" (click)="onUpdate()"  > 
            {{'pages.suscribeToInsurance.editButton' | translate}} 
          </button>
        </div>

      </form>
    </div>
  </div>
