import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderConditionsComponent } from 'src/app/pages/order-conditions/order-conditions.component';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';
import Swal from 'sweetalert2';
import { EditInsuranceDataComponent } from './edit-insurance-data/edit-insurance-data.component';
import { TranslateService } from '@ngx-translate/core';



declare var $;


@Component({
  selector: 'app-subscribe-insurance',
  templateUrl: './subscribe-insurance.component.html',
  styleUrls: ['./subscribe-insurance.component.css'],
  providers: [DatePipe]
})
export class SubscribeInsuranceComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @Output()
  selectionChange: EventEmitter<MatSelectChange>

  // showPersonnalData: boolean = true;
  // showCoverageChoice: boolean = false;
  // showFamily: boolean = false;
  // showMedicalRecord: boolean = false;
  // showFinal: boolean = false;

  panelOpenState = false;

  maxAvailableDate: Date;
  maxDate: string;
  hep: boolean = null;
  todayDate = new Date();
  // isFamilial: boolean = false;
  haveSpouse: boolean = false;
  readyToAdd: boolean = false;
  isLinear: boolean = true;
  hasCard: boolean = null;
  cardFound: boolean = false;
  hideCardForm: boolean = false;
  cashPayment: boolean = false;
  allowPayment: boolean = false;
  showPaymentStep: boolean = false;
  hideMedRecordForm: boolean = true;
  showStepper: boolean = false;
  alreadyPayed: boolean = false;
  // otherRate: boolean = false;
  // hideRateSelect: boolean = false;
  seen: boolean = false;
  saved: boolean = false;
  end: boolean = false;
  addBeneficiaire: boolean = false;
  showFamilyButtons: boolean = false;
  showResumeButtons: boolean = false;
  fillMedicalRecord: boolean = true;
  canGoToResume: boolean = false;
  defaultIndex: any = 0;
  numberOfCard = 0;
  numberOfPerson = 0;
  selectedMember = null;
  totalAmount = 0;
  ref_insurance: any = null;
  insuranceFees = 850000;
  cardPrice = 150000;
  transaction_number: any = null;
  savedInsurance: any = null;

  grabPoints: any;
  place: any = {};
  family: any[] = [];
  person: any;
  insurance: any;
  medicalRecords: any[] = [];
  // extGuarantiesList: any[] = [];
  children = 0;
  addedSpouse: number = 0;
  addedChildren: number = 0;
  selectedIndex: number = 0;
  addedFamilyMember: number = 0;
  mainMedRecordAdded: number = 0;
  spouseMedRecordAdded: number = 0;
  childrenMedRecordAdded: number = 0;
  memberMedRecordAdded: number = 0;
  insured: any = null;
  concernedMedRecord: string = "principal";
  private productName: string = "Carte de Santé Teliya";

  // COVERAGE: any =[
  //   {value: 60},
  //   {value: 80},
  //   {value: 100}
  // ];

  // COVERAGE_TYPE: any =[
  //   {value: "Individuelle"},
  //   {value: "Familiale"}
  // ];
  // EXTENDED_GUARANTIES: any =[
  //   {value: "Prothèse dentaire"},
  //   {value: "Soins dentaires"},
  //   {value: "Prothèse lunetterie"},
  //   {value: "Assistance (évacuation, soins à l'étranger)"},
  // ];
  gender: any = [
    { value: "male" },
    { value: "female"},
  ];
  RELATION: any = [
    { value: "spouse" },
    { value: "child" },
    // {value: "Autre"}
  ];

  findCardFormGroup = new FormGroup({
    cardNumber: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
  });
  grabFormGroup = new FormGroup({
    grabPoint: new FormControl('', Validators.required),
    coverageStartDate: new FormControl('')
  });
  secondFormGroup = new FormGroup({
    coverageRate: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
    coverageType: new FormControl('', Validators.required),
    extended_guaranties: new FormControl(''),
    haveSpouse: new FormControl(false),
    numberOfChildren: new FormControl(0)
  });
  firstFormGroup = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    lastname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.email, Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(15)]),
    gender: new FormControl('', Validators.required),
    birthday: new FormControl('', [Validators.required,]),
    address: new FormControl('')

  });

  familyFormGroup = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    lastname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    relationship: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    birthday: new FormControl('', [Validators.required,]),
    phone: new FormControl(''),
  });
  traitementSuiviFormGroup = new FormGroup({
    lastMeetPhysician: new FormControl('',),
    reasonMeetPhysician: new FormControl('', [, Validators.maxLength(100)]),
  });
  traitementSuiviFormGroup1 = new FormGroup({
    lastMeetDentist: new FormControl('',),
    reasonMeetDentist: new FormControl('',),
    hadEyesightControlLast2Years: new FormControl(false,),
    hadHearingTestLast2Years: new FormControl(false,),
  });
  traitementSuiviFormGroup2 = new FormGroup({
    lastVaccin: new FormControl('',),
    vaccinationDate: new FormControl('', [Validators.maxLength(200)]),
  });

  currentHealthStateFormGroup = new FormGroup({
    arterialHypertension: new FormControl('',),
    arthritis: new FormControl('',),
    diabetes: new FormControl('',),
    hepatitis: new FormControl('',),
    hepatitisType: new FormControl('',),
    drinkBeerOrWine: new FormControl(false,),
    particularDiet: new FormControl(false,)
  });
  familyHistoryFormGroup = new FormGroup({
    hadFatherInfarctionOrSuddenDeath55: new FormControl(false,),
    hadBrotherInfarctionOrSuddenDeath55: new FormControl(false,),
    hadMotherInfarctionOrSuddenDeath65: new FormControl(false,),
    hadSisterInfarctionOrSuddenDeath65: new FormControl(false,),
    hadMotherAVCBefore45: new FormControl(false,),
    hadFatherAVCBefore45: new FormControl(false,),
    hadBrotherAVCBefore45: new FormControl(false,),
    hadSisterAVCBefore45: new FormControl(false,),
    hasMotherArterialTension: new FormControl(false,),
    hasFatherArterialTension: new FormControl(false,),
    hasBrotherArterialTension: new FormControl(false,),
    hasSisterArterialTension: new FormControl(false,),
    hasMotherDiabetes: new FormControl(false,),
    hasFatherDiabetes: new FormControl(false,),
    hasBrotherDiabetes: new FormControl(false,),
    hasSisterDiabetes: new FormControl(false,),
    hadFatherDepression: new FormControl(false,),
    hadMotherDepression: new FormControl(false,),
    hadBrotherDepression: new FormControl(false,),
    hadSisterDepression: new FormControl(false,),
    hadMotherCancerOrOtheDiseases: new FormControl(false,),
    hadFatherCancerOrOtheDiseases: new FormControl(false,),
    hadBrotherCancerOrOtheDiseases: new FormControl(false,),
    hadSisterCancerOrOtheDiseases: new FormControl(false,),
    arterialTensionType: new FormControl('',),
    OtherDiseases: new FormControl('',),
  });

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private datePipe: DatePipe,
    public globalSvc: GlobalService,
    private OrderService: OrderService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.globalSvc.setTitle('Je Souscris à l\'assurance');
    this.dateAdapter.setLocale('fr');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    this.setMaxAvailableDate();
    this.getProduct(this.productName);
    this.getcardGrabPoints();
    if (this.activatedRoute.snapshot.params.id) {
      this.ref_insurance = this.activatedRoute.snapshot.params.id;
      this.getStoredData(this.ref_insurance);
      // this.defaultIndex =  3 : 2;
      this.saved = true;

    }
  }
  getStoredData(ref_insurance){
    let data = {
      ref_insurance: ref_insurance
    };
    this.globalSvc.showNgxLoader();
    this.OrderService.getStoredInsuranceData(data).subscribe(
      (res) => {
        console.log(res['data']);
        if (res['data'].length > 0) {
          let stored_insurance = res['data'][0];
          this.savedInsurance = stored_insurance;
          this.insurance = {
            coverageRate: 0,
            coverageType: " ",
            // extendedGuaranties: this.extGuarantiesList,
            montant: this.totalAmount,
            insurance_fees: this.insuranceFees*this.numberOfPerson,
            nb_card: this.numberOfCard,
            nb_person: this.numberOfPerson,
            grab_point: stored_insurance.grab_point,
            coverageStartDate: null
          }
          this.transaction_number = stored_insurance.transaction_number;
          // let people = stored_insurance.people;
          this.alreadyPayed = (stored_insurance.status_pay === "SUCCESS" || stored_insurance.status_pay === "ACCEPT")? true : false;
          let i=0;
          for(let person of stored_insurance.people){
            if(i<1){
              this.person = {
                firstname: person.first_name,
                lastname: person.last_name,
                email: person.email,
                phone: person.phone,
                gender: person.gender,
                birthday: person.birth_day,
                hasCard: person.hasCard,
                address: person.address,
                // hasMedical: false
              }
            }else{
              let order = {
                relationship: person.status,
                firstname: person.first_name,
                lastname: person.last_name,
                email: person.email,
                phone: person.phone,
                gender: person.gender,
                birthday: person.birth_day
              }
              this.family.push(order);
            }
            i++;
          }
          this.numberOfCard = stored_insurance.nb_card;
          this.numberOfPerson = stored_insurance.nb_person;
          this.totalAmount = stored_insurance.montant;
          this.setHasCard(this.person.hasCard);
          this.isLinear = false;
          this.end = true;
          this.showPaymentStep = true;
        } else {

        }
        // this.loading = false;
        this.globalSvc.spinner.hide();
      },
      (error) => {
        // this.loading = false;
        this.globalSvc.spinner.hide();
        // console.log(error);
      }
    );

  }

  setMaxAvailableDate() {
    this.maxDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
    const currentYear = new Date().getFullYear();
    const currentMonth = +this.maxDate.substring(0, 2);
    const currentDay = +this.maxDate.substring(3, 5);
    this.maxAvailableDate = new Date(currentYear, currentMonth - 1, currentDay);
  }
  // checkCoverageType(coverage){
  //   console.log(coverage);
  //   if(coverage == 'Individuelle'){
  //     this.isFamilial = false
  //     this.family = [];
  //     this.haveSpouse = false;
  //     this.children = 0;
  //     this.secondFormGroup.get('haveSpouse').setValue(false);

  //   }else{
  //     this.isFamilial = true
  //   }

  // }
  getNumberFamilyInfo() {
    console.log(this.secondFormGroup.get('numberOfChildren').value);
    console.log(this.secondFormGroup.get('haveSpouse').value);
  }
  getNumberOfChildren(nbre) {
    this.children = nbre;
    console.log(this.children);

  }
  getHaveSpouse(ev) {
    this.haveSpouse = ev.checked;
    console.log(this.haveSpouse);

    console.log(this.secondFormGroup.get('haveSpouse').value);
  }
  initFamilyForm() {
    this.familyFormGroup = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      relationship: new FormControl(''),
      gender: new FormControl('', Validators.required),
      birthday: new FormControl('', [Validators.required,]),
      phone: new FormControl(''),
    });
    this.readyToAdd = true;
  }
  addFamilyMember() {
    this.scrollToTop();
    const order = {
      firstname: this.familyFormGroup.get('firstname').value,
      lastname: this.familyFormGroup.get('lastname').value,
      relationship: this.familyFormGroup.get('relationship').value,
      gender: this.familyFormGroup.get('gender').value,
      birthday: this.familyFormGroup.get('birthday').value,
      phone: this.familyFormGroup.get('phone').value,
      hasMedical: false
    }
    this.addedFamilyMember++;
    this.readyToAdd = false;
    this.family.push(order);
    this.familyFormGroup.reset();
    this.getNumberOfCard();
  }
  async addMainPerson() {
    this.scrollToTop();
    this.person = {
      firstname: this.firstFormGroup.get('firstname').value,
      lastname: this.firstFormGroup.get('lastname').value,
      email: this.firstFormGroup.get('email').value,
      phone: this.firstFormGroup.get('phone').value,
      gender: this.firstFormGroup.get('gender').value,
      birthday: this.firstFormGroup.get('birthday').value,
      hasCard: (this.insured != null) ? true : false,
      address: this.firstFormGroup.get('address').value,
      hasMedical: false
    }
    await this.toastr.success(
      '', 
      this.translateService.instant('operationSuccessBenefitsAdd'), 
      {
      timeOut: 6000,
      tapToDismiss: true,
      progressBar: false,
    });
    this.firstFormGroup.reset();
    // console.log(this.person);
    this.getNumberOfCard();

  }

  resetFamilyForm() {
    this.familyFormGroup.get('firstname').setValue(''),
      this.familyFormGroup.get('lastname').setValue(''),
      this.familyFormGroup.get('gender').setValue(''),
      this.familyFormGroup.get('birthday').setValue(''),
      this.familyFormGroup.get('phone').setValue('')
  }
  setHasCard(hasCard: boolean) {
    this.hasCard = hasCard;
  }


  verifyCardNumber() {
    let datas = {
      number: this.findCardFormGroup.get('cardNumber').value,
      sourcePlatform: 'website'
    }

    this.OrderService.checkInsured(datas).subscribe(async data => {
      console.log(data);

      if (data['data'] !== null) {
        this.findCardFormGroup.reset();
        console.log(data);
        this.insured = data['data'];
        this.prefillFirstForm(this.insured);
        this.cardFound = true;
        this.hideCardForm = true
        await this.toastr.info(
          '', 
          this.translateService.instant('matchFound'), 
          {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: false,
        });
      }
      else {
        await this.toastr.warning(
          '',
          this.translateService.instant('noMatchForNumber'),
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: false,
          }
        );
        this.findCardFormGroup.reset();

      }
    },
      error => console.log(error)
    );
  }
  prefillFirstForm(insured) {
    this.firstFormGroup.get('firstname').setValue(insured.firstname);
    this.firstFormGroup.get('lastname').setValue(insured.lastname);
    this.firstFormGroup.get('email').setValue(insured.email);
    this.firstFormGroup.get('phone').setValue(insured.phone);
    this.firstFormGroup.get('gender').setValue(insured.gender);
    this.firstFormGroup.get('birthday').setValue(insured.birth_day);
    // this.firstFormGroup.get('birth_place').setValue(insured.place_of_birth);
    // this.firstFormGroup.get('profession').setValue(insured.profession);
    this.firstFormGroup.get('address').setValue(insured.address);
  }
  addMedicalRecord() {
    this.scrollToTop();
    const med = {
      concerned: this.concernedMedRecord,
      position: this.selectedMember,
      // traitement & suivi
      lastMeetPhysician: this.traitementSuiviFormGroup.get('lastMeetPhysician').value,
      reasonMeetPhysician: this.traitementSuiviFormGroup.get('reasonMeetPhysician').value,
      lastMeetDentist: this.traitementSuiviFormGroup1.get('lastMeetDentist').value,
      reasonMeetDentist: this.traitementSuiviFormGroup1.get('reasonMeetDentist').value,
      hadEyesightControlLast2Years: this.traitementSuiviFormGroup1.get('hadEyesightControlLast2Years').value,
      hadHearingTestLast2Years: this.traitementSuiviFormGroup1.get('hadHearingTestLast2Years').value,
      lastVaccin: this.traitementSuiviFormGroup2.get('lastVaccin').value,
      vaccinationDate: this.traitementSuiviFormGroup2.get('vaccinationDate').value,
      //etat actuel
      arterialHypertension: this.currentHealthStateFormGroup.get('arterialHypertension').value,
      arthritis: this.currentHealthStateFormGroup.get('arthritis').value,
      diabetes: this.currentHealthStateFormGroup.get('diabetes').value,
      hepatitis: this.currentHealthStateFormGroup.get('hepatitis').value,
      hepatitisType: this.currentHealthStateFormGroup.get('hepatitisType').value,
      drinkBeerOrWine: this.currentHealthStateFormGroup.get('drinkBeerOrWine').value,
      particularDiet: this.currentHealthStateFormGroup.get('particularDiet').value,
      // antécédents
      hadFatherInfarctionOrSuddenDeath55: this.familyHistoryFormGroup.get('hadFatherInfarctionOrSuddenDeath55').value,
      hadBrotherInfarctionOrSuddenDeath55: this.familyHistoryFormGroup.get('hadBrotherInfarctionOrSuddenDeath55').value,
      hadMotherInfarctionOrSuddenDeath65: this.familyHistoryFormGroup.get('hadMotherInfarctionOrSuddenDeath65').value,
      hadSisterInfarctionOrSuddenDeath65: this.familyHistoryFormGroup.get('hadSisterInfarctionOrSuddenDeath65').value,
      hadMotherAVCBefore45: this.familyHistoryFormGroup.get('hadMotherAVCBefore45').value,
      hadFatherAVCBefore45: this.familyHistoryFormGroup.get('hadFatherAVCBefore45').value,
      hadBrotherAVCBefore45: this.familyHistoryFormGroup.get('hadBrotherAVCBefore45').value,
      hadSisterAVCBefore45: this.familyHistoryFormGroup.get('hadSisterAVCBefore45').value,
      hasMotherArterialTension: this.familyHistoryFormGroup.get('hasMotherArterialTension').value,
      hasFatherArterialTension: this.familyHistoryFormGroup.get('hasFatherArterialTension').value,
      hasBrotherArterialTension: this.familyHistoryFormGroup.get('hasBrotherArterialTension').value,
      hasSisterArterialTension: this.familyHistoryFormGroup.get('hasSisterArterialTension').value,
      hasMotherDiabetes: this.familyHistoryFormGroup.get('hasMotherDiabetes').value,
      hasFatherDiabetes: this.familyHistoryFormGroup.get('hasFatherDiabetes').value,
      hasBrotherDiabetes: this.familyHistoryFormGroup.get('hasBrotherDiabetes').value,
      hasSisterDiabetes: this.familyHistoryFormGroup.get('hasSisterDiabetes').value,
      hadFatherDepression: this.familyHistoryFormGroup.get('hadFatherDepression').value,
      hadMotherDepression: this.familyHistoryFormGroup.get('hadMotherDepression').value,
      hadBrotherDepression: this.familyHistoryFormGroup.get('hadBrotherDepression').value,
      hadSisterDepression: this.familyHistoryFormGroup.get('hadSisterDepression').value,
      hadMotherCancerOrOtheDiseases: this.familyHistoryFormGroup.get('hadMotherCancerOrOtheDiseases').value,
      hadFatherCancerOrOtheDiseases: this.familyHistoryFormGroup.get('hadFatherCancerOrOtheDiseases').value,
      hadBrotherCancerOrOtheDiseases: this.familyHistoryFormGroup.get('hadBrotherCancerOrOtheDiseases').value,
      hadSisterCancerOrOtheDiseases: this.familyHistoryFormGroup.get('hadSisterCancerOrOtheDiseases').value,
      arterialTensionType: this.familyHistoryFormGroup.get('arterialTensionType').value,
      OtherDiseases: this.familyHistoryFormGroup.get('OtherDiseases').value,
    }
    if (med.concerned == 'principal') {
      this.mainMedRecordAdded++;
      this.hideMedRecordForm = true;
      // if(this.addedSpouse == 1 && this.addedChildren < this.children){
      //   this.familyFormGroup.get('relationship').setValue('Enfant');
      // }
    } else {
      this.memberMedRecordAdded++;
      this.hideMedRecordForm = true;
      // if(this.memberMedRecordAdded == this.numberOfPerson -1){
      // }
    }
    this.medicalRecords.push(med);
    this.setAddedMedical(this.selectedMember);
    console.log(this.medicalRecords);
    this.hideMedRecordForm = true;
    this.selectedMember = null;
    this.resetMedicalRecordForm();
    console.log(this.medicalRecords);

  }
  saveData() {
    this.saved = true;
    this.globalSvc.showNgxLoader();
    // const donnee = {
    //   guaranties:  this.secondFormGroup.get("extended_guaranties").value
    // }

    this.insurance = {
      coverageRate: 0,
      coverageType: " ",
      // extendedGuaranties: this.extGuarantiesList,
      montant: this.totalAmount,
      insurance_fees: this.insuranceFees*this.numberOfPerson,
      nb_card: this.numberOfCard,
      nb_person: this.numberOfPerson,
      grab_point: this.grabFormGroup.get('grabPoint').value,
      coverageStartDate: null
    }
    // console.log(person);

    console.log(this.family);
    console.log(this.medicalRecords);
    // if (donnee.guaranties != null) {
    //   for (let t of donnee.guaranties) {
    //     this.extGuarantiesList.push(t);
    //   }

    // }


    this.OrderService.sendInsuranceRequest(this.insurance, this.person, this.family, this.medicalRecords).subscribe(
      async (resp) => {
        this.globalSvc.spinner.hide();
        console.log(resp);
        this.savedInsurance = resp['data'];
        console.log(this.savedInsurance);

        this.transaction_number = this.savedInsurance.transaction_number;
        await this.toastr.success(
          '', 
          this.translateService.instant('operationSuccess'), 
          {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: false,
        });
        this.end = true;
        this.showPaymentStep = true;
        this.defaultIndex = 1;
        // this.router.navigateByUrl('/insurance-request-notification');

      },
      async (error) => {
        this.end = false;
        this.saved = false;
        this.showPaymentStep = false;
        this.globalSvc.spinner.hide();
        await this.toastr.error(
          '', 
          this.translateService.instant('errorOccurred'),
          {
          timeOut: 3000,
          tapToDismiss: true,
          progressBar: false,
        });
        console.log(error);

      }
    )
  }

  setConcernedMedRecord(position, data) {
    console.log(position);
    console.log(position);

    let med: any = this.medicalRecords.find((ln) => ln.position === position);
    console.log(med);

    if (this.selectedMember !== null && this.selectedMember !== position) {
      Swal.fire({
        title: this.translateService.instant('changePersonTitle'), // Translate the title
        text: this.translateService.instant('changePersonText'), // Translate the text
        position: 'center',
        showCancelButton: true,
        cancelButtonText: this.translateService.instant('noButtonText'), // Translate the cancel button text
        confirmButtonText: this.translateService.instant('yesButtonText'), // Translate the confirm button text
        confirmButtonColor: '#c7181e',
        icon: 'question'
      }).then((result) => {
        if (result.value) {
          if (med) {
            this.fillMedicalForms(med);
          }
          this.concernedMedRecord = data;
          this.selectedMember = position;
          this.hideMedRecordForm = false;
        }
      });
    } else {
      if (med) {
        this.fillMedicalForms(med);
      }
      this.concernedMedRecord = data;
      this.selectedMember = position;
      this.hideMedRecordForm = false;
    }
  }

  /*setConcernedMedRecord(position, data) {
console.log(position);
console.log(position);

    let med: any = this.medicalRecords.find(( ln ) => ln.position === position);
            console.log(med);
    if(this.selectedMember !== null && this.selectedMember !== position){
      Swal.fire({
        title: 'Changement de personne',
        text: 'Voulez vous changez de personne? Vous risquez de perdre les modifications non enregistrées!',
        position: 'center',
        showCancelButton: true,
        // cancelButtonColor: '#c7181e',
        cancelButtonText: 'Non',
        confirmButtonText: 'Oui',
        confirmButtonColor: '#c7181e',
        icon: 'question'
      }).then((result)=>{
        if(result.value){
          if(med){
            this.fillMedicalForms(med);
          }
          this.concernedMedRecord = data;
          // this.selectedIndex = 0;
          this.selectedMember = position;
          this.hideMedRecordForm = false;
        }
      });
    }else{
      if(med){
        this.fillMedicalForms(med);
      }
      this.concernedMedRecord = data;
      // this.selectedIndex = 0;
      this.selectedMember = position;
      this.hideMedRecordForm = false;
    }
  }*/

  nextStep() {
    // this.scrollToTop();
    if (this.selectedIndex != 2) {
      this.selectedIndex += 1;
    }
    this.globalSvc.fixDisplay();
  }
  previousStep() {
    // this.scrollToTop();
    if (this.selectedIndex != 0) {
      this.selectedIndex -= 1;
    }
    this.globalSvc.fixDisplay();
  }
  resetMedicalRecordForm() {
    this.traitementSuiviFormGroup.reset();
    this.traitementSuiviFormGroup1.reset();
    this.traitementSuiviFormGroup2.reset();
    this.currentHealthStateFormGroup.reset();
    this.familyHistoryFormGroup.reset();
  }
  // onAddRate(event: MatCheckboxChange){

  //   if(event.checked){
  //     console.log(event.checked)
  //     this.hideRateSelect = event.checked;
  //     this.otherRate = event.checked;
  //   } else{
  //     this.otherRate = false;
  //     this.hideRateSelect = false;
  //   }

  // }
  hepatitisState(value: boolean) {
    this.hep = value;
  }
  scrollToTop() {
    this.globalSvc.fixDisplay();
  }


  onCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.addBeneficiaire = event.checked;
    } else {
      this.addBeneficiaire = false;
    }
  }
  onCheckboxChange1(event: MatCheckboxChange) {
    if (event.checked) {
      this.seen = event.checked;
    } else {
      this.seen = false;
    }
  }
  getPlace(place) {
    this.place = place;
    // console.log(this.place);

  }
  getcardGrabPoints() {
    let isgrabpoint = true;
    this.OrderService.getCardGrabPoints(isgrabpoint).subscribe(
      (res) => {
        this.grabPoints = res['data'];
        // console.log(res['data']);
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      id: 1,
      title: "Edition"
    }
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    dialogConfig.autoFocus = false;

    const dialogRef = this.matDialog.open(OrderConditionsComponent, dialogConfig);

  }

  public update(index, item) {
    // console.log(index);


    let indexe = index

    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: 1,
      title: "Edition"
    }
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    dialogConfig.autoFocus = true;
    dialogConfig.data = item

    const dialogRef = this.matDialog.open(EditInsuranceDataComponent, dialogConfig);


    // dialogRef.afterClosed().subscribe( result => {

    // });


    dialogRef.afterClosed().subscribe(
      data => {
        console.log(data);
        // console.log(this.clients);

        // this.clients.map(cl => {
        //   console.log(cl.index == data.index);

        //   if(cl.index == data.index) {
        //     console.log(cl);
        //     cl = null
        //     // cl = data;
        //     console.log('trouve');

        //   }
        // });
        if (data) {
          if (indexe === -1) {
            this.person = data;
          } else {
            this.family[indexe] = data;
          }
          this.getNumberOfCard();
        }

        // console.log(this.clients);
        // this.saveOrder();

        // this.clients=data;
      }

    );

  }
  showPayment() {
    this.cashPayment = false;
    this.allowPayment = true;
  }
  showCashPayment() {
    this.cashPayment = true;
    this.allowPayment = false;
  }
  public payement() {
    // if (this.ref_insurance) {
    //   this.om_order = {
    //     montant: this.data_.montant,
    //     order_id: this.ref_insurance
    //   }
    // } else {
    let insurance_info = {
      montant: this.savedInsurance.montant,
      order_id: this.savedInsurance.ref_insurance,
      person: this.person,
      quantity: this.numberOfCard,
      nbpers: this.numberOfPerson,
      prix_carte: this.cardPrice,
      fees: this.insuranceFees,
      isInsurance: true
    }
    // }
    // // this.loading = true;

    this.globalSvc.showNgxLoader();
    this.OrderService.initPayment(insurance_info).subscribe(async resp => {
      console.log(resp);
      if (resp['data'] !== "FAILED") {
        // return;
        window.location.href = resp['data'];
      } else {
        await this.toastr.warning(
          '',
          this.translateService.instant('paymentInitiationFailure'),
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: false,
          }
        );

        this.globalSvc.spinner.hide();
      }

    }, async error => {
      // this.loading = false;
      this.globalSvc.spinner.hide();
      await this.toastr.error(
        '',
        this.translateService.instant('operationFailure'),
        {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: true,
        }
      );
      console.error(error)
    });

  }
  payementVisa() {
    this.globalSvc.showNgxLoader();
    // window.location.href ='https://visa.teliya.net/sa-wm/payment_form.php?data=' + this.savedInsurance.ref_insurance;
    let paramss = {
      // profile_id : "C05EF21D-A208-4ED9-B5A1-E62679B87612", // dev
      profile_id: "3DC5E00C-E138-4C24-B706-8E99B33B9D1B", //prod
      // access_key : "1f9d96dcbcbd39599d9a7e3bedf35747",  // dev
      access_key: "f98f9065463735f9bae5c69b481d9733", // prod
      transaction_uuid: "61042ddd80f59",
      signed_date_time: "2021-07-30T16:50:37Z",
      signed_field_names: "profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page",
      unsigned_field_names: "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4",
      transaction_type: "sale",
      reference_number: this.savedInsurance.ref_insurance,
      auth_trans_ref_no: this.savedInsurance.ref_insurance,
      amount: this.savedInsurance.montant,
      // currency: "GHS", //dev
      currency: "GNF", // prod
      locale: "en-us",
      merchant_descriptor: "Swen",
      bill_to_forename: "Noreal",
      bill_to_surname: "Name",
      bill_to_email: "null@cybersource.com",
      bill_to_phone: "+662-2962-000",
      bill_to_address_line1: "1295 Charleston Rd",
      bill_to_address_line2: "1295 Charleston Rd",
      bill_to_address_city: "Mountain View",
      bill_to_address_state: "CA",
      bill_to_address_country: "US",
      bill_to_address_postal_code: "94043",
      // override_custom_cancel_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
      // override_custom_receipt_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
      override_custom_cancel_page: this.globalSvc.eco_visa_notif_url,  // prod
      override_custom_receipt_page: this.globalSvc.eco_visa_notif_url, // prod
      customer_ip_address: "::1",
      line_item_count: "2",
      merchant_defined_data1: "MDD#1",
      merchant_defined_data2: "MDD#2",
      merchant_defined_data3: "MDD#3",
      merchant_defined_data4: "MDD#4",
      signature: "uvSvinvz2Q9a+CoYdBGOUNTn9bv1U0lOD98kIXjR0Ws=",

      // données pour la notification par mail de teliya admin
      transaction_number: this.transaction_number,
      quantity: this.numberOfCard,
      nbpers: this.numberOfPerson,
      montant: this.cardPrice,
      fees: this.insuranceFees,
      person: this.person.firstname + " " + this.person.lastname,
      email: this.person.email,
      phone: this.person.phone,
      grab_point: this.insurance.grab_point,
      isInsurance: true
    }

    this.OrderService.visadata(paramss).subscribe(resp => {
      // console.log(resp);
      paramss.transaction_uuid = resp['uuid'];
      paramss.signed_date_time = resp['datetime'];
      paramss.signature = resp['signature'];
      // this.paramss.reference_number = resp['ref'];
      // this.paramss.auth_trans_ref_no = resp['auth'];
      console.log(paramss);
      $.redirect("https://secureacceptance.cybersource.com/pay", paramss, 'POST');
    }, async error => {
      console.error(error);
      this.globalSvc.spinner.hide();
    });
  }
  getNumberOfCard() {
    this.numberOfCard = 0;
    this.numberOfPerson = 0;
    if (this.person) {
      this.numberOfPerson++;
      (+this.globalSvc.calculateAge(this.person.birthday) >= 18 ? this.numberOfCard++ : this.numberOfCard = this.numberOfCard + 0);
    }
    if (this.family && this.family.length > 0) {
      for (let member of this.family) {
        this.numberOfPerson++;
        (+this.globalSvc.calculateAge(member.birthday) >= 18 ? this.numberOfCard++ : this.numberOfCard = this.numberOfCard + 0);
      }
    }
    if (this.numberOfCard === 0) {
      this.numberOfCard++;
    }
    this.totalAmount = this.calculateFinalAmount();
    console.log(this.numberOfCard);
    this.scrollToTop();
  }
  calculateFinalAmount() {
    let total = this.cardPrice * this.numberOfCard + this.numberOfPerson * this.insuranceFees;
    return total;
  }

  sendFinalMessage() {
    this.globalSvc.showNgxLoader();
    let message = "Bonjour <span style=\"color: green\">" + this.person.firstname + "</span>, <br> Votre numéro de demande d'assurance est le : <strong>" +
      this.transaction_number + "</strong>. <br><br>Rendez-vous à '<strong>" +
      this.insurance.grab_point + "</strong>' pour effectuer le paiement  et finaliser votre demande.";
    let email = this.person.email;
    let data = {
      transaction_number: this.transaction_number,
      quantity: this.numberOfCard,
      nbpers: this.numberOfPerson,
      montant: this.cardPrice,
      fees: this.insuranceFees,
      client: this.person.firstname + " " + this.person.lastname,
      email: this.person.email,
      phone: this.person.phone,
      grab_point: this.insurance.grab_point
    }
    this.OrderService.sendFinalInsuranceMail(email, message, data).subscribe((data) => {
      console.log(data);
      this.globalSvc.spinner.hide();
      this.router.navigateByUrl('/insurance-request-notification');
    },
      (error) => {
        console.log(error);
        this.globalSvc.spinner.hide();
        this.router.navigateByUrl('/insurance-request-notification');
      }
    );
  }
  setFillMethod(selected) {
    this.fillMedicalRecord = selected;
    if(this.fillMedicalRecord){
      ((this.family.length +1) === this.medicalRecords.length ) ?
      this.canGoToResume = true : this.canGoToResume = false;
    }else{
      this.canGoToResume = true;
    }
  }
  setAddedMedical(index) {
    if (index === -1) {
      this.person.hasMedical = true;
    } else {
      console.log(this.family[index]);

      this.family[index].hasMedical = true;
    }
  }
  fillMedicalForms(med) {
    // if(this.selectedMember !== null && this.selectedMember !== index){
      // Swal.fire({
      //   title: 'Changement de personne',
      //   text: 'Voulez vous changez de personne? Vous risquez de perdre les modifications non enregistrées!',
      //   position: 'center',
      //   showCancelButton: true,
      //   // cancelButtonColor: '#c7181e',
      //   cancelButtonText: 'Non',
      //   confirmButtonText: 'Oui',
      //   confirmButtonColor: '#c7181e',
      //   icon: 'question'
      // }).then((result)=>{
      //     if(result.value){
            // console.log("oui");
            // this.setConcernedMedRecord(index, relationship);
            // let med: any = this.medicalRecords.find(({ position }) => position === index);
            // console.log(med);

            if (med) {
              // traitement & suivi

              this.traitementSuiviFormGroup.get('lastMeetPhysician').setValue(med.lastMeetPhysician);
              this.traitementSuiviFormGroup.get('reasonMeetPhysician').setValue(med.reasonMeetPhysician);
              this.traitementSuiviFormGroup1.get('lastMeetDentist').setValue(med.lastMeetDentist);
              this.traitementSuiviFormGroup1.get('reasonMeetDentist').setValue(med.reasonMeetDentist);
              this.traitementSuiviFormGroup1.get('hadEyesightControlLast2Years').setValue(med.hadEyesightControlLast2Years);
              this.traitementSuiviFormGroup1.get('hadHearingTestLast2Years').setValue(med.hadHearingTestLast2Years);
              this.traitementSuiviFormGroup2.get('lastVaccin').setValue(med.lastVaccin);
              this.traitementSuiviFormGroup2.get('vaccinationDate').setValue(med.vaccinationDate);
              //etat actuel
              this.currentHealthStateFormGroup.get('arterialHypertension').setValue(med.arterialHypertension);
              this.currentHealthStateFormGroup.get('arthritis').setValue(med.arthritis);
              this.currentHealthStateFormGroup.get('diabetes').setValue(med.diabetes);
              this.currentHealthStateFormGroup.get('hepatitis').setValue(med.hepatitis);
              this.currentHealthStateFormGroup.get('hepatitisType').setValue(med.hepatitisType);
              this.currentHealthStateFormGroup.get('drinkBeerOrWine').setValue(med.drinkBeerOrWine);
              this.currentHealthStateFormGroup.get('particularDiet').setValue(med.particularDiet);
              // antécédents
              this.familyHistoryFormGroup.get('hadFatherInfarctionOrSuddenDeath55').setValue(med.hadFatherInfarctionOrSuddenDeath55);
              this.familyHistoryFormGroup.get('hadBrotherInfarctionOrSuddenDeath55').setValue(med.hadBrotherInfarctionOrSuddenDeath55);
              this.familyHistoryFormGroup.get('hadMotherInfarctionOrSuddenDeath65').setValue(med.hadMotherInfarctionOrSuddenDeath65);
              this.familyHistoryFormGroup.get('hadSisterInfarctionOrSuddenDeath65').setValue(med.hadSisterInfarctionOrSuddenDeath65);
              this.familyHistoryFormGroup.get('hadMotherAVCBefore45').setValue(med.hadMotherAVCBefore45);
              this.familyHistoryFormGroup.get('hadFatherAVCBefore45').setValue(med.hadFatherAVCBefore45);
              this.familyHistoryFormGroup.get('hadBrotherAVCBefore45').setValue(med.hadBrotherAVCBefore45);
              this.familyHistoryFormGroup.get('hadSisterAVCBefore45').setValue(med.hadSisterAVCBefore45);
              this.familyHistoryFormGroup.get('hasMotherArterialTension').setValue(med.hasMotherArterialTension);
              this.familyHistoryFormGroup.get('hasFatherArterialTension').setValue(med.hasFatherArterialTension);
              this.familyHistoryFormGroup.get('hasBrotherArterialTension').setValue(med.hasBrotherArterialTension);
              this.familyHistoryFormGroup.get('hasSisterArterialTension').setValue(med.hasSisterArterialTension);
              this.familyHistoryFormGroup.get('hasMotherDiabetes').setValue(med.hasMotherDiabetes);
              this.familyHistoryFormGroup.get('hasFatherDiabetes').setValue(med.hasFatherDiabetes);
              this.familyHistoryFormGroup.get('hasBrotherDiabetes').setValue(med.hasBrotherDiabetes);
              this.familyHistoryFormGroup.get('hasSisterDiabetes').setValue(med.hasSisterDiabetes);
              this.familyHistoryFormGroup.get('hadFatherDepression').setValue(med.hadFatherDepression);
              this.familyHistoryFormGroup.get('hadMotherDepression').setValue(med.hadMotherDepression);
              this.familyHistoryFormGroup.get('hadBrotherDepression').setValue(med.hadBrotherDepression);
              this.familyHistoryFormGroup.get('hadSisterDepression').setValue(med.hadSisterDepression);
              this.familyHistoryFormGroup.get('hadMotherCancerOrOtheDiseases').setValue(med.hadMotherCancerOrOtheDiseases);
              this.familyHistoryFormGroup.get('hadFatherCancerOrOtheDiseases').setValue(med.hadFatherCancerOrOtheDiseases);
              this.familyHistoryFormGroup.get('hadBrotherCancerOrOtheDiseases').setValue(med.hadBrotherCancerOrOtheDiseases);
              this.familyHistoryFormGroup.get('hadSisterCancerOrOtheDiseases').setValue(med.hadSisterCancerOrOtheDiseases);
              this.familyHistoryFormGroup.get('arterialTensionType').setValue(med.arterialTensionType);
              this.familyHistoryFormGroup.get('OtherDiseases').setValue(med.OtherDiseases);
            }
          // }
      //   }
      // );
    // }
  }
  getProduct(product) {
    this.OrderService.getProduct(product).subscribe(resp => {
      console.log(resp);
      if (resp['product'] !== null) {
        let prod = resp['product'];
        this.cardPrice = prod.price;
        console.log(this.cardPrice);
      }
    },
      async error => {
        await this.toastr.error(
          '',
          this.translateService.instant("errorMessageLoading"),
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: true,
          }
        );
        console.log(error)
      }
    );
  }
}
