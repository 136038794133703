import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  product: any = null;
  private productName: string = "Carte de Santé Teliya";
  constructor(
    private globalSvc: GlobalService,
    private OrderService: OrderService,
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService

  ) {
    this.globalSvc.setTitle('Commande de carte');
  }

    ngOnInit(): void {
      this.globalSvc.fixDisplay();
      this.getProduct(this.productName);
  }

  getProduct(product){
    this.globalSvc.showNgxLoader();
    this.OrderService.getProduct(product).subscribe(resp =>{
      console.log(resp);
      this.globalSvc.spinner.hide();

        if(resp['product'] !== null)
        {
          this.product = resp['product'];
          console.log(this.product);
        }
      },
      async error =>{
        this.globalSvc.spinner.hide();
        await this.toastr.error(
          '',
            this.translateService.instant("errorMessageFetchingData"),
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: true,
          }
        );
        console.log(error)
      }
    );
  }
  goToOffer(offer: string){
    (offer === "hospi") ? this.router.navigateByUrl("/subscribe-to-insurance") : this.router.navigateByUrl("/checkout");
  }

}
