<div class="page-title-overlap  pt-4 posi" style="background-color:  #3db549;">
  <div class="container d-lg-flex justify-content-between">
    <div class="order-lg-2 mb-1 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home"><i class="fa fa-home"></i> {{"pages.breadcrumb.home" | translate }} </a>
          </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page"> {{"pages.breadcrumb.individual" | translate }} </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page"> {{"pages.breadcrumb.command" | translate }} </li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <h1 class="h3 text-light mb-1"></h1>
    </div>
  </div>
</div>
<!--Intro Section-->
<!-- <section class="backcolor"> -->


<section id="about-5" class="about-section division" style="background-color: #f8f9fb;">
  <!-- style="background-image: url(../../../../assets/img/commande1.png);  background-position: center; background-repeat: no-repeat; background-size: cover ; height: 610px;"> -->
  <picture>
    <img sizes="(max-width: 2100px) 100vw, 2100px" srcset="
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_190.png 190w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_551.png 551w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_793.png 793w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_995.png 995w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1147.png 1147w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1298.png 1298w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1434.png 1434w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1556.png 1556w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1690.png 1690w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1793.png 1793w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_1903.png 1903w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_2003.png 2003w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_2080.png 2080w,
        ../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_2100.png 2100w
      " src="../../../../assets/img/commande1_jmmwvz/commande1_jmmwvz_c_scale,w_2100.png" alt="photo de la carte">
  </picture>
</section>

<div class="shell">
  <div class="container">
    <div class="row">
      <div class="col-md-5 col-lg-4 col-sm-6 offset-md-1 offset-lg-2">
        <!-- <div>
            <p>
              Notre offre la plus populaire <i class="fa fa-certificate" style="color: gold;"></i>
            </p>
          </div> -->
        <div class="wsk-cp-product">
          <!-- <div class="wsk-cp-img">
              <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
            </div> -->
          <div class="wsk-cp-text">
            <!-- <div class="category">
                <span>200.000 GNF</span>
              </div> -->
            <div class="title-product">
              <h3> {{"pages.commander.formuleCard.formulCardSimple.formuleTitle" | translate }} </h3>
            </div>
            <div class="text-center mb-3">
              <span>{{ (product?.price ? product?.price : '200000' )| number}} GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                  work now check this link</p> -->
              <div>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardSimple.DSE" | translate }}  </span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardSimple.wallet" | translate }}</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardSimple.healthcare" | translate }}</span><br><br>
                <span style="text-decoration: line-through; color: red;"><i class="fa fa-window-close-o"
                    aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardSimple.coverage" | translate }} </span><br>
              </div>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded" (click)="goToOffer('default')">{{"pages.commander.formuleCard.formulCardSimple.buttonCommander" | translate }}</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
                <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-4 col-sm-6">
        <div class="wsk-cp-product">
          <!-- <div class="wsk-cp-img">
              <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
            </div> -->
          <div class="wsk-cp-text">
            <!-- <div class="category">
                <span>1.000.000 GNF</span>
              </div> -->
            <div class="title-product">
              <h3>{{"pages.commander.formuleCard.formulCardHospi.formuleTitle" | translate }}</h3>
            </div>
            <div class="text-center mb-3">
              <span>{{'1000000' | number}} GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                  work now check this link</p> -->
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardHospi.DSE" | translate }}</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardHospi.wallet" | translate }}</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardHospi.healthcare" | translate }} </span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> {{"pages.commander.formuleCard.formulCardHospi.coverage" | translate }}</span><br>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded" (click)="goToOffer('hospi')">{{"pages.commander.formuleCard.formulCardHospi.buttonOffer" | translate }}</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
                <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="text-center" style="background-color: #f8f9fb;">
    <div class="container section-couveture ">
      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px; line-height: 1.1em;">
        Votre carte de santé Teliya à seulement {{ (product?.price ? product?.price : '150000' )| number}} GNF
      </h2>
      <p class="text-center pt5" style="color: #033b55;">
        Avec une livraison gratuite chez l'un de nos partenaires
      </p>
      <br>
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <p class="pt5">
            Avoir son dossier médical partagé <br>
            Possibilité de souscrire à une assurance <br>
            Un Portemonnaie santé électronique
          </p>
        </div>
      </div>



      <p class="tite pt-2">
        <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/checkout" data-wow-delay="0.3s">
          <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> JE COMMANDE
        </a>
      </p>
      <br>

    </div>
  </div>

  <div style="background-color: #f8f9fb;">
    <div class="container section-couveture ">
      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px;">
        Les principaux avantages de la carte de santé
      </h2>
      <br>

      <div class="col-sm-8 offset-sm-2">
        <p class="pt5">
          <i class="fa fa-check-circle mr-lg-4 fa-2x" style="color: #00c851;"> </i> Meilleure offre d'assurance maladie
          <br>
          <i class="fa fa-check-circle mr-lg-4 fa-2x" style="color: #00c851;"> </i> Facilite le suivi médical <br>
          <i class="fa fa-check-circle mr-lg-4 fa-2x" style="color: #00c851;"> </i> Portemonnaie de santé <br>
          <i class="fa fa-check-circle mr-lg-4 fa-2x" style="color: #00c851;"> </i> Couverture territoriale <br>
          <i class="fa fa-check-circle mr-lg-4 fa-2x" style="color: #00c851;"> </i> Accès à un réseau de professionnel
          de
          santé qualifié
        </p>


        <p class="tite pt-2">
          <a routerLink="/the-card">
            <span class="mb-5" style=" font-size: 1.6rem; font-weight: bold; color: #033b55;">Plus
              d'avantages....</span>
          </a>
        </p>
        <br>
        <p class="tite text-center pt-2">
          <a class="btn btn-success wow fadeInLeft text-white mt-2" style=" font-size: 1.6rem;" routerLink="/checkout"
            data-wow-delay="0.3s">
            <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> COMMANDER
          </a>
        </p>
        <br>
      </div>

    </div>
  </div> -->

<!-- </section> -->
