
<section class="backcolor">
  <div style="background-image: url(../../../../assets/img/carte3.png); background-repeat: no-repeat; background-size: cover">
    <div class="container">
      <div class="row text-center pt-4">

        <div class="col-12">
          <h1 class=" text-center mt-3">
            {{ "pages.insurancePage.healthInsuranceSubscription.title" | translate }}
          </h1>
          <p class="ml-3">
            <i class="fa fa-check-square-o mr-2" style="background-color: white; color: #033B55;"></i> <span class="text-center ">{{ "pages.insurancePage.healthInsuranceSubscription.features.0" | translate }}</span>  <br>
            <i class="fa fa-check-square-o mr-2" style="background-color: white; color: #033B55;"></i> <span class="text-center ">{{ "pages.insurancePage.healthInsuranceSubscription.features.1" | translate }}</span>  <br>
            <i class="fa fa-check-square-o mr-2" style="background-color: white; color: #033B55;"></i> <span class="text-center ">{{ "pages.insurancePage.healthInsuranceSubscription.features.2" | translate }}</span>  <br>
          </p>
          <p class="text-center mt-3">
            <!-- <a class="btn"  (click)="showInDevelopmentMessage()" style="background-color: #033B55; color: white; font-size: 20px;">Mon offre d'assurance maladie</a> -->
            <a class="btn"  routerLink="/subscribe-to-insurance" style="background-color: #033B55; color: white; font-size: 20px;"> {{ "pages.insurancePage.healthInsuranceSubscription.buttonText" | translate }} </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
  <!-- <div class="conatainer">
    <div class="row text-center">
      <div class="col-md-10 offset-md-1">
        <h1 class="mt-3 mb-2">
          Souscrire une assurance santé, c’est se prémunir financièrement contre tous les risques pouvant affecter
          votre santé, tout en bénéficiant des meilleurs soins
        </h1>
        <p>
          L'assurance maladie vous aide à payer vos frais de santé. Ainsi, vous pouvez obtenir les soins dont vous avez
          besoin quand vous en avez besoin. Vous n'avez pas besoin d'attendre que votre problème médical soit très grave
          pour vous faire soigner. <br>
          En plus de l'accès à des soins de santé, souscrire à une assurance maladie est une protection contre la ruine
          financière car elle est conçue pour couvrir les assurés contre les dépenses hospitalières et médicale. <br>
          Il est important de trouver une assurance maladie adaptée à vos besoins et à votre budget, Teliya santé fait un
          choix pour vous parmis les meilleures offres disponibles sur le marché.
        </p>
      </div>
    </div>
  </div> -->
</div>
<!-- <div>
  <div class="conatainer">
    <div class="row text-center">
      <div class="col-md-10 offset-md-1">
        <h1 class="mt-3 mb-2">
          Pourquoi souscrire à une Assurance maladie?
        </h1>
        <div class="mb-2">
          <p>
            La vie est imprévisible, il est impossible de prédire l'heure de la maladie ou de la blessure et les coûts
            engendrés
            peuvent mettre vos finances à l'envers. Vous pouvez planifier cette situation incertaine en souscrivant
            simplement à une
            assurance maladie <br>
          </p>

          <ul>
            <li>
              <p>
                L'assurance médicale vous protège des urgences médicales non annoncées. Il peut tout couvrir, à partir
                des frais
                d'ambulance aux frais de salle d'opération, des frais de médicaments, etc. Et Cela vous aidera à
                économiser de l'argent.
              </p>
            </li>
            <li>
              <p>
                Vous avez le choix parmi un large éventail d’hôpitaux de réseau pour être traité dans les meilleures
                conditions
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="principal">
  <div class="conatainer">
    <div class="row text-center">
      <div class="col-md-10 offset-md-1" style="font-weight: bold;">
        <h1 class="mt-3 mb-2">
          Comment l’Assurance maladie rembourse-t-elle les frais de santé ?
        </h1>
        <p style="font-weight: normal;">
          Le remboursement des soins par l’Assurance maladie est automatisé. Il suffit de présenter <span style="color: #033B55;">votre carte de santé Teliya</span>,
          dispositif contenant toutes vos informations d’assuré , pour obtenir une prise en charge des soins.
        </p>
      </div>
    </div>
  </div>
</div> -->
<div class="pt-3 mb-2">
  <mat-accordion>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="mt3- mb-3" style="height: 100%;">
        <mat-panel-title >
          {{ "pages.insurancePage.healthInsuranceSubscrip.title" | translate }}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <span class="titre"> {{ "pages.insurancePage.healthInsuranceSubscrip.description" | translate }} </span><br><br>
      <p>
        {{ "pages.insurancePage.healthInsuranceSubscrip.benefits.0" | translate }} <br>
        {{ "pages.insurancePage.healthInsuranceSubscrip.benefits.1" | translate }} <br>
        {{ "pages.insurancePage.healthInsuranceSubscrip.benefits.2" | translate }}
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="mt-2 mb-2" style="height: 100%;">
        <mat-panel-title >
          {{ "pages.insurancePage.whySubscribe.title" | translate }}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="mb-2">
        <p>
          {{ "pages.insurancePage.whySubscribe.description" | translate }} <br>
        </p>

        <ul>
          <li>
            <p>
              {{ "pages.insurancePage.whySubscribe.benefits.0" | translate }}
            </p>
          </li>
          <li>
            <p>
              {{ "pages.insurancePage.whySubscribe.benefits.1" | translate }}
            </p>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="mt-2 mb-2" style="height: 100%;">
        <mat-panel-title  >
          {{ "pages.insurancePage.howReimbursementWorks.title" | translate }}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p style="font-weight: normal;">
        {{ "pages.insurancePage.howReimbursementWorks.description" | translate }}
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>


</section>

