<div class="container">
  <div>
    <div class="pt-3 pb-3">
      <h2 class="text-center" *ngIf="hasCard === null && !alreadyPayed">
        {{"pages.suscribeToInsurance.h2Text" | translate }}
      </h2>
      <div class="text-center" *ngIf="!alreadyPayed && ref_insurance === null">
        <mat-radio-group aria-label="">
          <mat-label class="mb-2">{{"pages.suscribeToInsurance.radioGroupLabel" | translate }}</mat-label><br>
          <mat-radio-button value="hasCard" (click)="setHasCard(true)">{{"pages.suscribeToInsurance.radioYes" | translate }}
          </mat-radio-button>
          <mat-radio-button value="hasNoCard" (click)="setHasCard(false)">{{"pages.suscribeToInsurance.radioNo" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>


      <div class="text-center" *ngIf="hasCard && !hideCardForm && !alreadyPayed">
        <form [formGroup]="findCardFormGroup" class="">

          <mat-form-field class="">
            <mat-label>{{"pages.suscribeToInsurance.healthCardNumberLabel" | translate }}</mat-label>
            <input matInput placeholder="{{'pages.suscribeToInsurance.healthCardNumberLabel' | translate }}" name="cardNumber" formControlName="cardNumber"
              minlength="8" maxlength="12" required>
          </mat-form-field>
          <div class="mt-2">
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="verifyCardNumber()"
              [disabled]="findCardFormGroup.invalid">{{"pages.suscribeToInsurance.validateButton" | translate }}</button>
          </div>
        </form>
      </div>

      <mat-horizontal-stepper *ngIf="this.hasCard !== null && (!this.hasCard  || this.cardFound) && !alreadyPayed"
        [selectedIndex]="defaultIndex" #stepper [linear]="isLinear"
      >
        <mat-step [completed]="person" label="Membres" *ngIf="this.ref_insurance === null && !showPaymentStep">

          <ng-template matStepLabel> {{"pages.suscribeToInsurance.personalData" | translate }} </ng-template>
          <div class="row p-0">
            <div class="col-md-6 p-0">
              <form [formGroup]="firstFormGroup" class="" *ngIf="!person">
                <div class="row p-0">
                  <div class="col-md-8 offset-md-2 col-xs-12">
                    <h4 class="text-center">
                      {{"pages.suscribeToInsurance.mainPerson" | translate }}
                    </h4>
                  </div>
                </div>
                <div class="row p-0">
                  <div class="col-md-5 col-xs-12">

                    <mat-form-field class="example-full-width ">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.firstName' | translate }}" name="first" formControlName="firstname" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.lastName' | translate }}" formControlName="lastname" name="second" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>{{"pages.suscribeToInsurance.gender" | translate }}</mat-label>
                      <mat-select required name="genre" formControlName="gender">
                        <mat-option *ngFor="let gr of gender" [value]="gr.value">
                          {{gr.value | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>{{"pages.suscribeToInsurance.birthDate" | translate }}</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="JJ/MM/AAAA"
                        formControlName="birthday" required>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <input matInput type="number" placeholder="{{'pages.suscribeToInsurance.phoneNumber' | translate }}" formControlName="phone" required
                        minlength="9" maxlength="12">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.email' | translate }}" formControlName="email"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.address' | translate }}" formControlName="address">
                    </mat-form-field>

                    <div class="mt-2">
                      <button class="ml-2 mb-1" [disabled]="firstFormGroup.invalid" mat-raised-button color="primary"
                        (click)="addMainPerson()" *ngIf="!person">{{"pages.suscribeToInsurance.addButton" | translate }}</button>
                    </div>
                  </div>
                </div>
              </form>
              <form [formGroup]="familyFormGroup" class="" *ngIf="person">
                <div class="row p-0">
                  <div class="col-md-8 offset-md-2 col-xs-12">
                    <h4 class="text-center">
                      {{"pages.suscribeToInsurance.familyMember" | translate }}
                    </h4>
                  </div>
                </div>
                <div class="row p-0">
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width" *ngIf="person">
                      <mat-label>{{"pages.suscribeToInsurance.relation" | translate }}</mat-label>
                      <mat-select required name="relation" formControlName="relationship">
                        <mat-option *ngFor="let gr of RELATION" [value]="gr.value">
                          {{gr.value | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width ">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.firstName' | translate }}" name="first" formControlName="firstname" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="{{'pages.suscribeToInsurance.lastName' | translate }}" formControlName="lastname" name="second" required>
                    </mat-form-field>

                  </div>
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'pages.suscribeToInsurance.gender' | translate }}</mat-label>
                      <mat-select required name="genre" formControlName="gender">
                        <mat-option *ngFor="let gr of gender" [value]="gr.value">
                          {{gr.value | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>{{'pages.suscribeToInsurance.birthDate' | translate }}</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="JJ/MM/AAAA"
                        formControlName="birthday" required>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput type="number" placeholder="n° téléphone" formControlName="phone" minlength="9"
                        maxlength="12">
                    </mat-form-field>

                    <div class="mt-2">
                      <button class="ml-2 mb-1" [disabled]="familyFormGroup.invalid" mat-raised-button color="primary"
                        (click)="addFamilyMember()">{{'pages.suscribeToInsurance.addButton' | translate }}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <div class="col-12 table-responsive" style="min-height: 130px !important;">
                <table *ngIf="person || family?.length > 0" class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">#</th>
                      <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.1' | translate }}</th>
                      <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.2' | translate }}</th>
                      <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.3' | translate }}</th>
                      <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.4' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="person" class="text-center">
                      <th scope="row">1</th>
                      <td>{{person?.lastname}}</td>
                      <td>{{person?.firstname}}</td>
                      <td>{{person?.phone}}</td>
                      <td>{{person?.email}}</td>

                    </tr>
                    <tr *ngFor="let client of family; let i = index" class="text-center">
                      <th scope="row">{{i +2}}</th>
                      <td>{{client?.lastname}}</td>
                      <td>{{client?.firstname}}</td>
                      <td>{{client?.phone}}</td>
                      <td>{{client?.email ? client?.email : '---' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="person || family.length > 0" class="pt-4">
                <hr>
                <div class="mt-1 pull-right">
                  <button class="mr-2 mb-1" mat-raised-button color="warn">{{'pages.suscribeToInsurance.cancelButton' | translate }}</button>
                  <button class="ml-2 mb-1" mat-raised-button color="primary" matStepperNext>{{'pages.suscribeToInsurance.nextButton' | translate }}</button>
                </div>
              </div>

            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="grabFormGroup" *ngIf="this.ref_insurance === null && !showPaymentStep">
          <ng-template matStepLabel> {{'pages.suscribeToInsurance.grabPoint.title' | translate }} </ng-template>
          <form [formGroup]="grabFormGroup" class="example-form">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>{{'pages.suscribeToInsurance.grabPoint.pointOfGrab' | translate }}</mat-label>
              <mat-select formControlName="grabPoint">
                <mat-option *ngFor="let place of grabPoints" placeholder="{{'pages.suscribeToInsurance.grabPoint.pointOfGrabCard' | translate }}" 
                  value="{{place?.name}}" (click)="getPlace(place)">{{place?.name}} - ({{place?.address}})</mat-option>
                <!-- <mat-option value="option">Kipé</mat-option> -->
              </mat-select>
            </mat-form-field>
            <div class="row mb-2" *ngIf="place?.length !==0">
              <div class="col-sm-6 col-xs-12">
                {{'pages.suscribeToInsurance.grabPoint.addressGrab' | translate }}: {{ place?.address }}
              </div>
              <div class="col-sm-6 col-xs-12">
                {{'pages.suscribeToInsurance.grabPoint.phoneGrab' | translate }}: {{ place?.phone }}
              </div>
            </div><br>
            <div class="row">
              <div class="col-12">
                <mat-checkbox name="seen" [(checked)]="seen" (change)="onCheckboxChange1($event)">
                  {{'pages.suscribeToInsurance.grabPoint.readAndAccept' | translate }}
                  <a (click)="openDialog1()">
                    <span style="color: blue">{{'pages.suscribeToInsurance.grabPoint.conditions' | translate }}</span>
                  </a>
                </mat-checkbox>

              </div>
            </div>
            <div class="mt-2">

              <button class="mr-2 mb-1" mat-raised-button color="accent" matStepperPrevious>{{'pages.suscribeToInsurance.grabPoint.previous' | translate }}</button>
              <button class="ml-2 mb-1" mat-raised-button color="primary" [disabled]="!grabFormGroup.valid || !seen"
               matStepperNext>{{'pages.suscribeToInsurance.grabPoint.next' | translate }}</button>
            </div>
          </form>
        </mat-step>
        <!-- dossier médical -->
        <mat-step label="{{'pages.suscribeToInsurance.questionnary.medicalQuestionnary' | translate }}" *ngIf="this.ref_insurance === null && !showPaymentStep" optional>
          <!-- <div class="ml-1 text-center"> -->
          <div class="row p-0">
            <div class="col-md-6 p-0">
              <div class="row p-0">
                <div class="col-md-12 col-xs-12">
                  <mat-radio-group aria-label="">
                    <div class="row p-0">
                      <div class="col-md-6">
                        <mat-radio-button value="fillOnline" (click)="setFillMethod(true)" checked>
                          {{"pages.suscribeToInsurance.questionnary.fillNowLabel"| translate }}
                        </mat-radio-button>
                      </div>
                      <div class="col-md-6">
                        <mat-radio-button value="fillAtGrab" (click)="setFillMethod(false)">
                          {{"pages.suscribeToInsurance.questionnary.fillAtGrabLabel"| translate }}
                        </mat-radio-button>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>


          <!-- </div> -->
          <div *ngIf="fillMedicalRecord">
            <div class="row">
              <div class="col-12 table-responsive">
                <table *ngIf="person || family?.length >0" class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">#</th>
                      <th scope="col">{{"pages.suscribeToInsurance.questionnary.lastName"| translate }}</th>
                      <th scope="col">{{"pages.suscribeToInsurance.questionnary.firstName"| translate }}</th>
                      <th scope="col">{{"pages.suscribeToInsurance.questionnary.birthDay"| translate }}</th>
                      <!-- <th scope="col">Genre</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Email</th> -->
                      <td class="text-center">{{"pages.suscribeToInsurance.questionnary.action"| translate }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="person" class="text-center">
                      <th scope="row"> <i *ngIf="selectedMember === -1" style="color: green;" class="fa fa-asterisk"
                          aria-hidden="true"></i> 1</th>
                      <td>{{person?.lastname}}</td>
                      <td>{{person?.firstname}}</td>
                      <td>{{person.birthday | date: "dd/MM/yyyy"}}</td>
                      <!-- <td>{{person?.gender}}</td>
                      <td>{{person?.phone}}</td>
                      <td>{{person?.email}}</td>
                      <td>{{person?.address}}</td> -->
                      <td class="text-center">
                        <i (click)="setConcernedMedRecord(-1,'principal')" style="color: blue;"
                          *ngIf="!saved && !person?.hasMedical" class="fa fa-medkit" aria-hidden="true"></i>
                        <i *ngIf="!saved && person?.hasMedical" (click)="setConcernedMedRecord(-1,'principal')" style="color: green;" class="fa fa-check"
                          aria-hidden="true"></i>
                      </td>
                    </tr>
                    <tr *ngFor="let item of family; let i = index" class="text-center">
                      <th scope="row"><i *ngIf="selectedMember === i" style="color: green;" class="fa fa-asterisk"
                          aria-hidden="true"></i> {{i+2}}</th>
                      <td>{{item?.lastname}}</td>
                      <td>{{item?.firstname}}</td>
                      <td>{{item.birthday | date: "dd/MM/yyyy"}}</td>
                      <!-- <td>{{item?.gender}}</td>
                      <td>{{item?.phone}}</td>
                      <td>{{item?.email ? item?.email : '---' }}</td>
                      <td>{{item?.address}}</td> -->
                      <td class="text-center">
                        <i (click)="setConcernedMedRecord(i,item?.relationship)" style="color: blue;"
                          *ngIf="!saved && !item?.hasMedical" class="fa fa-medkit" aria-hidden="true"></i>
                        <i *ngIf="!saved && item?.hasMedical" (click)="setConcernedMedRecord(i,item?.relationship)" class="fa fa-check" aria-hidden="true"
                          style="color: green;"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr>


           <mat-accordion *ngIf="!hideMedRecordForm">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"pages.suscribeToInsurance.questionnary.medicalTreatment"| translate }}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row p-0">
                  <div class="col-md-6">
                    <form [formGroup]="traitementSuiviFormGroup" class="">

                      <mat-form-field class="example-full-width">
                        <mat-label>{{"pages.suscribeToInsurance.questionnary.lastConsultationLabel"| translate }}</mat-label>
                        <input matInput [matDatepicker]="picker2" [max]="maxAvailableDate" placeholder="Date de la dernière consultation"
                          formControlName="lastMeetPhysician">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="example-full-width ">
                        <mat-label>{{"pages.suscribeToInsurance.questionnary.reasonLabel"| translate }}</mat-label>
                        <input matInput placeholder="Raison de la visite" name="reason" formControlName="reasonMeetPhysician">
                      </mat-form-field>
                    </form>
                    <form [formGroup]="traitementSuiviFormGroup2" class="">
                      <mat-form-field class="example-full-width ">
                        <mat-label>{{"pages.suscribeToInsurance.questionnary.lastVaccinationLabel"| translate }}</mat-label>
                        <input matInput placeholder="Dernière vaccination" name="vaccin" formControlName="lastVaccin">
                      </mat-form-field>
                      <mat-form-field class="example-full-width">
                        <mat-label> {{"pages.suscribeToInsurance.questionnary.vaccinationDateLabel"| translate }} </mat-label>
                        <input matInput [matDatepicker]="picker4" [max]="maxAvailableDate" placeholder="Date de vaccination"
                          formControlName="vaccinationDate">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                      <!-- <div class="mt-2">
                        <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()"
                          matStepperPrevious>Précédent</button>
                        <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="nextStep()">Suivant</button>
                      </div> -->
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form [formGroup]="traitementSuiviFormGroup1" class="">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{"pages.suscribeToInsurance.questionnary.lastDentistConsultationLabel"| translate }} </mat-label>
                        <input matInput [matDatepicker]="picker3" [max]="maxAvailableDate"
                          placeholder="Date de la dernière consultation" formControlName="lastMeetDentist">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="example-full-width">
                        <mat-label> {{"pages.suscribeToInsurance.questionnary.reasonDentistLabel"| translate }} </mat-label>
                        <mat-select name="raison" formControlName="reasonMeetDentist">
                          <mat-option value="Urgence">{{"pages.suscribeToInsurance.questionnary.emergency"| translate }}</mat-option>
                          <mat-option value="Soins dentaires">{{"pages.suscribeToInsurance.questionnary.dentalCare"| translate }}</mat-option>
                          <mat-option value="Contrôle">{{"pages.suscribeToInsurance.questionnary.checkup"| translate }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="row">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.eyesightControlLabel"| translate }}</mat-label>
                          <br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hadEyesightControlLast2Years">
                            <mat-radio-button value="true">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="false">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.hearingTestLabel"| translate }}</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hadHearingTestLast2Years">
                            <mat-radio-button value="true">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="false">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"pages.suscribeToInsurance.questionnary.currentHealthState"| translate }}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="currentHealthStateFormGroup" class="">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label> {{"pages.suscribeToInsurance.questionnary.arterialHypertensionLabel"| translate }} </mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arterialHypertension">
                            <mat-radio-button value="Oui">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">{{"pages.suscribeToInsurance.questionnary.dk"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br> 
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label> {{"pages.suscribeToInsurance.questionnary.arthritisLabel"| translate }} </mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arthritis">
                            <mat-radio-button value="Oui">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">{{"pages.suscribeToInsurance.questionnary.dk"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.diabetesLabel"| translate }}</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="diabetes">
                            <mat-radio-button value="Oui">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">{{"pages.suscribeToInsurance.questionnary.dk"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.hepatitisLabel"| translate }}</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hepatitis">
                            <mat-radio-button value="Oui" (click)="hepatitisState(true)">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non" (click)="hepatitisState(false)">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas" (click)="hepatitisState(false)">{{"pages.suscribeToInsurance.questionnary.dk"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2" *ngIf="hep">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.hepatitisTypeLabel"| translate }}</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hepatitisType">
                            <mat-radio-button value="A">A
                            </mat-radio-button>
                            <mat-radio-button value="B">B
                            </mat-radio-button>
                            <mat-radio-button value="C">C
                            </mat-radio-button>
                            <mat-radio-button value="D">D
                            </mat-radio-button>
                            <mat-radio-button value="E">E
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>
                            {{"pages.suscribeToInsurance.questionnary.drinkBeerOrWineLabel"| translate }}
                          </mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="drinkBeerOrWine">
                            <mat-radio-button value="Oui">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>{{"pages.suscribeToInsurance.questionnary.dietaryHabits"| translate }}</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="particularDiet">
                            <mat-radio-button value="Oui">{{"pages.suscribeToInsurance.questionnary.yes"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Non">{{"pages.suscribeToInsurance.questionnary.no"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{"pages.suscribeToInsurance.questionnary.familyHistory"| translate }}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <form [formGroup]="familyHistoryFormGroup" class="">
                  <div class="row">
                    <div class="col-12 text-center">
                      <p>{{"pages.suscribeToInsurance.questionnary.familyMemberHistory"| translate }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>
                            {{"pages.suscribeToInsurance.questionnary.hadInfarctionOrSuddenDeath55Label"| translate }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherInfarctionOrSuddenDeath55">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherInfarctionOrSuddenDeath65">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherInfarctionOrSuddenDeath55">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterInfarctionOrSuddenDeath65">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>{{"pages.suscribeToInsurance.questionnary.hadAVCBefore45Label"| translate }}</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherAVCBefore45">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherAVCBefore45">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherAVCBefore45">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterAVCBefore45">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p> {{"pages.suscribeToInsurance.questionnary.arterialTensionTypeLabel"| translate }}</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arterialTensionType">
                            <mat-radio-button value="Hyper"> {{"pages.suscribeToInsurance.questionnary.typeHyper"| translate }}
                            </mat-radio-button>
                            <mat-radio-button value="Hypo"> {{"pages.suscribeToInsurance.questionnary.typeHypo"| translate }}
                            </mat-radio-button>
                          </mat-radio-group><br>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasFatherArterialTension">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasMotherArterialTension">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasBrotherArterialTension">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasSisterArterialTension">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>{{"pages.suscribeToInsurance.questionnary.hasDiabetesLabel"| translate }}</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hasFatherDiabetes">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasMotherDiabetes">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasBrotherDiabetes">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasSisterDiabetes">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p> {{"pages.suscribeToInsurance.questionnary.hadDepressionLabel"| translate }}</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherDepression">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherDepression">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherDepression">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterDepression">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p> {{"pages.suscribeToInsurance.questionnary.hadCancerOrOtheDiseasesLabel"| translate }} </p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherCancerOrOtheDiseases">
                            {{"pages.suscribeToInsurance.questionnary.father"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherCancerOrOtheDiseases">
                            {{"pages.suscribeToInsurance.questionnary.mother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherCancerOrOtheDiseases">
                            {{"pages.suscribeToInsurance.questionnary.brother"| translate }}
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterCancerOrOtheDiseases">
                            {{"pages.suscribeToInsurance.questionnary.sister"| translate }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-expansion-panel>
              <div class="mt-2">
                <!-- <button class="mr-2 mb-1" mat-raised-button (click)="previousStep()"
                  color="accent">Précédent</button> -->
                <button class="mb-1" (click)="addMedicalRecord()"  mat-raised-button color="primary"
                  [disabled]="!this.traitementSuiviFormGroup.dirty && !this.currentHealthStateFormGroup.dirty && !this.familyHistoryFormGroup.dirty"
                > 
                  {{"pages.suscribeToInsurance.questionnary.validateButtonLabel"| translate }}
                </button>
              </div>
            </mat-accordion>
          </div>
          <div class="mt-3" class="pull-right">
            <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()"
              matStepperPrevious>{{"pages.suscribeToInsurance.questionnary.previousButtonLabel"| translate }}</button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()"
              matStepperNext [disabled]="!canGoToResume"
            >{{"pages.suscribeToInsurance.questionnary.nextButtonLabel"| translate }}</button>
          </div>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>{{"pages.suscribeToInsurance.summary"| translate }}</ng-template>
          <div class="row">
            <div class="col-12 table-responsive">
              <table *ngIf="person || family?.length >0" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.1' | translate }}</th>
                    <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.2' | translate }}</th>
                    <th scope="col">{{'pages.suscribeToInsurance.tableHeaders.5' | translate }}</th>
                    <!-- <th scope="col">Genre</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Email</th> -->
                    <td class="text-center">{{'pages.suscribeToInsurance.tableHeaders.6' | translate }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="person">
                    <th scope="row">1</th>
                    <td>{{person?.lastname}}</td>
                    <td>{{person?.firstname}}</td>
                    <td>{{person.birthday | date: "dd/MM/yyyy"}}</td>
                    <!-- <td>{{person?.gender}}</td>
                    <td>{{person?.phone}}</td>
                    <td>{{person?.email}}</td>
                    <td>{{person?.address}}</td> -->
                    <td class="text-center">
                      <i (click)="update(-1, person)" style="color: blue;" *ngIf="!saved" class="fa fa-edit"></i>
                    </td>
                  </tr>
                  <tr *ngFor="let item of family; let i = index">
                    <th scope="row">{{i +2}}</th>
                    <td>{{item?.lastname}}</td>
                    <td>{{item?.firstname}}</td>
                    <td>{{item.birthday | date: "dd/MM/yyyy"}}</td>
                    <!-- <td>{{item?.gender}}</td>
                    <td>{{item?.phone}}</td>
                    <td>{{item?.email}}</td>
                    <td>{{item?.address}}</td> -->
                    <td class="text-center">
                      <i (click)="update(i, item)" style="color: blue;" *ngIf="!saved" class="fa fa-edit"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6 offset-md-6 col-xs-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{'pages.suscribeToInsurance.tableHeaders.7' | translate }}</th>
                      <th>{{'pages.suscribeToInsurance.tableHeaders.8' | translate }}</th>
                      <th>{{'pages.suscribeToInsurance.tableHeaders.9' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{'pages.suscribeToInsurance.summaryPart.insurance' | translate }}</td>
                      <td>{{numberOfPerson}} {{'pages.suscribeToInsurance.summaryPart.persons' | translate }}</td>
                      <td>{{insuranceFees | number}}</td>
                      <td>{{(numberOfPerson * insuranceFees) | number}}</td>
                    </tr>
                    <tr>
                      <td> {{'pages.suscribeToInsurance.summaryPart.card' | translate }} </td>
                      <td>{{ numberOfCard}}</td>
                      <td>{{cardPrice | number}}</td>
                      <td>{{(numberOfCard * cardPrice) | number}}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>{{'pages.suscribeToInsurance.summaryPart.total' | translate }}</strong></td>
                      <td><strong>{{totalAmount | number}} {{globalSvc?.currency}}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-2" *ngIf="!end">

            <button class="ml-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()" matStepperPrevious
            >
            {{'pages.suscribeToInsurance.summaryPart.previous' | translate }}
            </button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="saveData()"
              [disabled]="saved">{{'pages.suscribeToInsurance.summaryPart.validate' | translate }}</button>

          </div>
          <div class="mt-2 pull-right" *ngIf="end"> 

            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()"
              matStepperNext>{{'pages.suscribeToInsurance.summaryPart.next' | translate }}</button>

          </div>

        </mat-step>
        <mat-step label="Paiement" *ngIf="showPaymentStep">
          <div class="row">
            <h3>{{'pages.suscribeToInsurance.summaryPart.pricingSummary' | translate }}</h3>
            <div class="col-sm-12">
              <table id="tbPartners" class="table table-hover" style="margin-top: 20px;">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{'pages.suscribeToInsurance.tableHeaders.7' | translate }}</th>
                    <th>{{'pages.suscribeToInsurance.tableHeaders.8' | translate }}</th>
                    <th>{{'pages.suscribeToInsurance.tableHeaders.9' | translate }}</th>
                    <!-- <th>Contact</th>
                      <th>Statut</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'pages.suscribeToInsurance.summaryPart.insurance' | translate }}</td>
                    <td>{{numberOfPerson}} {{'pages.suscribeToInsurance.summaryPart.persons' | translate }}</td>
                    <td>{{insuranceFees | number}}</td>
                    <td>{{(numberOfPerson * insuranceFees) | number}}</td>
                  </tr>
                  <tr>
                    <td>{{'pages.suscribeToInsurance.summaryPart.card' | translate }}</td>
                    <td>{{ numberOfCard}}</td>
                    <td>{{cardPrice | number}}</td>
                    <td>{{(numberOfCard * cardPrice) | number}}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td><strong>{{'pages.suscribeToInsurance.summaryPart.total' | translate }}</strong></td>
                    <td><strong>{{totalAmount | number}}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
              <div class="col-sm-6">
                Frais d'assurance par personne: <strong>{{insuranceFees | number}} {{globalSvc?.currency}}</strong>
                Nombre de personnes: {{numberOfPerson}}
              </div>
              <div class="col-sm-6">
                Prix de la carte: {{cardPrice | number}} {{globalSvc?.currency}} <br>
                Nombre de carte(s): {{ numberOfCard}} <br>
              </div>
            </div> -->
            <!-- <hr> -->
            <!-- Montant total à payer: <strong>{{totalAmount | number}} {{globalSvc?.currency}}</strong> -->
          </div>
          <div>
            {{'pages.suscribeToInsurance.summaryPart.paymentTypeQuestion' | translate }}
          </div>
          <div class="row mt-2">
            <div class="col-sm-4 col-xs-6 offset-sm-1">

            </div>
            <div class="col-sm-4 col-xs-6 offset-sm-2">

            </div>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showPayment()">
              {{'pages.suscribeToInsurance.summaryPart.onlinePayment' | translate }}
            </button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showCashPayment()">
              {{'pages.suscribeToInsurance.summaryPart.cashPayment' | translate }}
            </button>
          </div>
          <div class="mt-2 text-center" *ngIf="allowPayment">

            <p class="" style="font-size: 20px; font-weight: bold;"> {{'pages.suscribeToInsurance.summaryPart.paymentMethod' | translate }}</p>


            <button type="button" (click)="payement()" class="mr-3"
              style="background-image: url(../../../../assets/img/orange.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

            <button type="button" (click)="payementVisa()" class="mr-3"
              style="background-image: url(../../../../assets/img/card-visa.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

          </div>
          <div class="mt-2 text-center" *ngIf="cashPayment">

            <p class="" style="font-size: 20px; font-weight: bold;"> {{'pages.suscribeToInsurance.summaryPart.cashPayment' | translate }}</p>
            <div>
              {{'pages.suscribeToInsurance.summaryPart.cashPaymentInstructions' | translate }}
            </div>
            <div>
              <button mat-raised-button (click)="sendFinalMessage()">{{'pages.suscribeToInsurance.summaryPart.finish' | translate }}</button>
              <!-- <button mat-raised-button routerLink="/insurance-request-notification">Terminer</button> -->
            </div>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
      <div *ngIf="alreadyPayed">
        <h1 class="text-center pt-5">
          {{'pages.suscribeToInsurance.summaryPart.completeRequestMessage' | translate }} <span style="color: blue;">{{'pages.suscribeToInsurance.summaryPart.contactEmail' | translate }}</span> {{'pages.suscribeToInsurance.summaryPart.completeRequestMessageSuffix' | translate }} 
        </h1>
      </div>
    </div>
  </div>
</div>
