<app-slider></app-slider>


<section class="backcolor">

  <div class="text-center" style="background: #F7F8FB center center; color:#033B55;">
    <h1 style="font-size: 40px; " class="pt-3">{{ "pages.phome.approach" | translate }}</h1>
  </div>


  <section id="info-" style="background-color: #F7F8FB;" class="bg-blue info-section division pt-5">
    <!-- TEXT BLOCK -->
    <div class="container">
      <h1 class="text-center" style="font-weight: bold; font-size: 38px; color:#033B55;"> {{ "pages.phome.cardDescribeTitle" | translate }} </h1>

      <p class="text-center">
        {{ "pages.phome.cardDescribe" | translate}}
      </p>

      <div class="row align-items-center">
        <div class="col-xs-12 col-md-12 ">
          <div class="txt-block white-color ">
            <!-- Section ID -->
            <div class="row align-items-center text-center">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-md-3">
                    <img class="pt-2" src="../../../../assets/img/soins-de-qualite.png" alt="" width="200" height="100">
                    <!-- <img src="../../../../assets/img/couverture-sante.png" alt="" width="200" height="100"> -->
                    <p>
                      {{ "pages.phome.cardDescribeContent1" | translate}}
                    </p>

                  </div>
                  <div class="col-md-3">
                    <img src="../../../../assets/img/couverture-sante.png" alt="" width="200" height="100">
                    <!-- <img class="pt-2" src="../../../../assets/img/soins-de-qualite.png" alt="" width="200" height="100"> -->
                    <p>
                      <!-- La qualité de vos soins de santé -->
                      {{ "pages.phome.cardDescribeContent2" | translate}}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <img class="pt-4" src="../../../../assets/img/portefeuille.png" alt="" width="180" height="100">
                      <p class="mt-3">
                        {{ "pages.phome.cardDescribeContent3" | translate}}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <img src="../../../../assets/img/securite-donnees.png" alt="" width="200" height="90">
                      <p>
                        {{ "pages.phome.cardDescribeContent4" | translate}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            <!-- <div>
              <form id="payment_confirmation" action="https://testsecureacceptance.cybersource.com/pay" method="post">

                <fieldset id="confirmation">
                    <legend>Details du paiement</legend>
                    <div>
                        <div><span class="fieldName">reference_number</span><span class="fieldValue">B1627596071112</span></div>
                <div><span class="fieldName">amount</span><span class="fieldValue">10.00</span></div>
                <div><span class="fieldName">currency</span><span class="fieldValue">GHS</span></div>
                <div><span class="fieldName">Nom</span><span class="fieldValue">Noreal</span></div>
                <div><span class="fieldName">Nom</span><span class="fieldValue">Name</span></div>
                <div><span class="fieldName">Email</span><span class="fieldValue">null@cybersource.com</span></div>
                <div><span class="fieldName">Adresse</span><span class="fieldValue">1295 Charleston Rd</span></div>
                <div><span class="fieldName">Ville</span><span class="fieldValue">Mountain View</span></div>
                <div><span class="fieldName">Pays</span><span class="fieldValue">US</span></div>
                    </div>
                    </fieldset>

                    <input type="hidden" name="profile_id" value="C05EF21D-A208-4ED9-B5A1-E62679B87612">
                <input type="hidden" name="access_key" value="1f9d96dcbcbd39599d9a7e3bedf35747">
                <input type="hidden" name="transaction_uuid" value="61032794a3f8e">
                <input type="hidden" name="signed_date_time" value="2021-07-29T22:11:32Z">
                <input type="hidden" name="signed_field_names" value="profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page">
                <input type="hidden" name="unsigned_field_names" value="signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4">
                <input type="hidden" name="transaction_type" value="sale">
                <input type="hidden" name="reference_number" value="B1627596694843">
                <input type="hidden" name="auth_trans_ref_no" value="B1627596694843">
                <input type="hidden" name="amount" value="10.00">
                <input type="hidden" name="currency" value="GHS">
                <input type="hidden" name="locale" value="en-us">
                <input type="hidden" name="merchant_descriptor" value="Swen">
                <input type="hidden" name="bill_to_forename" value="Noreal">
                <input type="hidden" name="bill_to_surname" value="Name">
                <input type="hidden" name="bill_to_email" value="null@cybersource.com">
                <input type="hidden" name="bill_to_phone" value="+662-2962-000">
                <input type="hidden" name="bill_to_address_line1" value="1295 Charleston Rd">
                <input type="hidden" name="bill_to_address_line2" value="1295 Charleston Rd">
                <input type="hidden" name="bill_to_address_city" value="Mountain View">
                <input type="hidden" name="bill_to_address_state" value="CA">
                <input type="hidden" name="bill_to_address_country" value="US">
                <input type="hidden" name="bill_to_address_postal_code" value="94043">
                <input type="hidden" name="override_custom_cancel_page" value="http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/response.php">
                <input type="hidden" name="override_custom_receipt_page" value="http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/response.php">
                <input type="hidden" name="customer_ip_address" value="::1">
                <input type="hidden" name="line_item_count" value="2">
                <input type="hidden" name="merchant_defined_data1" value="MDD#1">
                <input type="hidden" name="merchant_defined_data2" value="MDD#2">
                <input type="hidden" name="merchant_defined_data3" value="MDD#3">
                <input type="hidden" name="merchant_defined_data4" value="MDD#4">

                    <input type="hidden" name="signature" value="v85jAh25f6kF36H7GN6w7SxS8pDYtwo6BBP1YwjiiBE=">
                    <input type="submit" id="btn_submit" value="Confirmer">

                </form>
            </div> -->

            <div class="text-center">
              <br>
              <p style="color: #033b4a; font-size: 23px; line-height: 22px;">
                {{"pages.phome.whyOrder" | translate}}
              </p>
              <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/commander" data-wow-delay="0.3s">
                <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> {{"pages.phome.buttonOrderCard" | translate}}
              </a>
            </div>

            <!-- <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe | safe: 'resourceUrl'"></iframe> -->

          </div>
        </div>
        <!-- <div class="col-md-3">
          <img src="../../../../assets/img/homme.png" alt="" width="100%" height="500">
        </div> -->
      </div>
    </div>
    </div>
  </section>
  <div style="background-image: url(../../../../assets/img/solution.png); background-size: 100% 100%;">
    <div class="container mb-5 mt-5">

      <div class="text-center mt-2 mb-2 ">
        <h1 class="text-center mb-2 pt-4" style="color: #033B55; font-size: 35px;">
          {{"pages.phome.solutionTitle" | translate}}
        </h1>

        <!--Grid row-->
        <p class="text-center">
          {{"pages.phome.solutionDescribe" | translate}} <br> <br>
        </p>
        <div class="row">

          <!--Grid column-->
          <div class="col-md-4">
            <span> <img class="bd-placeholder-img" width="200" height="130" src="../../../../assets/img/le-dmp.png"
                alt=""></span>
            <div class="text-center">
              <h4 class="font-weight-bold mb-1 size-20"> 
                {{"pages.phome.solution1TitleM" | translate}} <br>
                {{"pages.phome.solution1TitleS" | translate}} <br>
              </h4>
              <p class="colu" style=" color: black; line-height: 1.6em;" >
                {{"pages.phome.solution1Describe" | translate}}
              </p>
            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-4">
            <span> <img class="bd-placeholder-img" width="240" height="130"
                src="../../../../assets/img/une-couverture-sante.png" alt=""></span>
            <div class="text-center">
              <h4 class="font-weight-bold mb-1 size-20">{{"pages.phome.solution2Title" | translate}}</h4>
              <p class="colu" style=" color: black; line-height: 1.6em;"s>
                {{"pages.phome.solution2Describe" | translate}} <br>
                <a class=" text-center tra-white-hover mt-20" style="color: #3db549;"  routerLink="/insurance">{{"pages.phome.solution2SuscribeLink" | translate}}</a>
              </p>

            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-4 ">
            <span> <img class="bd-placeholder-img" width="240" height="130"
                src="../../../../assets/img/un-moyen-de-paiement.png" alt=""></span>
            <div class="text-center">
              <h4 class="font-weight-bold mb-1 size-20">{{"pages.phome.solution3Title" | translate}}</h4>
              <p class="colu" style=" color: black; line-height: 1.6em;">
                {{"pages.phome.solution3Describe" | translate}}
              </p>

            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->

          <!--Grid column-->

        </div>
        <!--Grid row-->
      </div>



    </div>
  </div>

  <div class="container">

    <div class="text-center mb-2 ">
      <h1 class="text-center mb-2" style="color: #033B55; font-size: 35px;">
        {{"pages.phome.whyoderCard" | translate}}
      </h1> <br>
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-md-3 mb-4">
          <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/simplification.svg"
              alt=""></span>
          <div class="text-center">
            <h4 class="font-weight-bold mb-2 size-20"> {{"pages.phome.whyOrderCard1title" | translate}}  </h4>
            <p class="colu" > {{"pages.phome.whyOrderCard1Content" | translate}} </p>
          </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
          <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/time.svg"
              alt=""></span>
          <div class="text-center">
            <h4 class="font-weight-bold mb-2 size-20">{{"pages.phome.whyOrderCard2title" | translate}}</h4>
            <p class="colu">{{"pages.phome.whyOrderCard2Content" | translate}}</p>
            <p>
              <a routerLink="/recharger" class="font-weight-bold green-text">{{"pages.phome.RechargeCardLink" | translate}}</a>
            </p>

          </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
          <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/optimization.svg"
              alt=""></span>
          <div class="text-center">
            <h4 class="font-weight-bold size-20 mb-2">{{"pages.phome.whyOrderCard3title" | translate}}</h4>
            <p>
              {{"pages.phome.whyOrderCard3Content" | translate}}
            </p>
          </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-3 mb-4">
          <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/security.svg"
              alt=""></span>
          <div class="text-center">
            <h4 class="font-weight-bold size-20 mb-2"> {{"pages.phome.whyOrderCard4title" | translate}} </h4>
            <p class="colu" >
              {{"pages.phome.whyOrderCard4Content" | translate}}
            </p>
          </div>
        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>



  </div>

  <section class="container text-center mt-2">
    <h1 class="text-center" style="font-weight: bold; font-size: 38px; color:#033B55;">
      {{"pages.phome.ProfessionalsofHeath" | translate }}
    </h1>
  </section>

  <!-- BANNER-7
        ============================================= -->
  <section id="banner-7" class="bg-fixed banner-section division mt-5"
    style="background-image: url(../../../../assets/img/un-reseau-de-soins.png); background-size: cover;
    background-position: center center;">
    <div class="container white-color">

      <div class="row d-flex align-items-center">

        <!-- BANNER TEXT -->
        <div class="col-md-8 col-lg-8 col-xl-8" style="background-color: #033B55; opacity: 0.8;">
          <div class="banner-txt wow fadeInUp" data-wow-delay="0.4s">

            <div style="color: white; opacity: inherit;">
              <h2 class="size-30 text-center pt-3"> {{"pages.phome.healthcare" | translate }}</h2>
              <p class="text-center" style="color: white;">
                {{"pages.phome.healthcareDetails" | translate }}
              </p>
              <ul style="font-size: 20px;">
                <li>
                  {{"pages.phome.healthcareDetails1" | translate }}
                </li>

                <li class="pt-2">
                  {{"pages.phome.healthcareDetails2" | translate }}
                </li>
                <li class="pt-2">
                  {{"pages.phome.healthcareDetails3" | translate }}
                </li>
              </ul>

            </div>
            <!-- Text -->

            <!-- Button -->


          </div>
          <a routerLink="/adhere" class=" btn btn-success text-center tra-white-hover mt-20">
            {{"pages.phome.joinNetworkCare" | translate }}
          </a>
        </div> <!-- END BANNER TEXT -->


      </div> <!-- End row -->
    </div> <!-- End container -->
  </section> <!-- END BANNER-7 -->


  <div style="background:#f7f8fb; color: black; ">
    <div class="container pt-5">
      <div class="row">
        <!--Grid column-->
        <div class="col-xs-12 col-md-4">

          <!--Grid row-->
          <div class="row">
            <div class="col-2">
              <i class="fa fa-2x fa-flag-checkered green-text"></i>
            </div>
            <div class="col-10 mb-3">
              <h5 class="font-weight-bold mb-3"> {{ "pages.phome.appAdvantage1Title" | translate }} </h5>
              <p>
                {{ "pages.phome.appAdvantageSecure" | translate }}              </p>
            </div>
          </div>
          <!--Grid row-->

          <!--Grid row-->
          <div class="row">
            <div class="col-2">
              <i class="fa fa-2x fa-flask green-text"></i>
            </div>
            <div class="col-10 mb-3">
              <h5 class="font-weight-bold mb-3">{{ "pages.phome.appAdvantage2Title" | translate }}</h5>
              <p>{{ "pages.phome.appAdvantageEasyToUse" | translate }}</p>
            </div>
          </div>
          <!--Grid row-->
        </div>

        <div class="col-md-4 col-xs-12 text-center text-md-left img-tag mt-1">
          <img src="../../../../assets/img/teliya_tab.png" alt="alternatif" class="z-depth-0" width="100%" height="240">
        </div>

        <div class="col-md-4 col-xs-12">

          <!--Grid row-->
          <div class="row ">
            <div class="col-2">
              <i class="fa fa-2x fa-heart green-text"></i>
            </div>
            <div class="col-10 mb-3">
              <h5 class="font-weight-bold mb-3 ">{{ "pages.phome.appAdvantage3Title" | translate }}</h5>
              <p >{{ "pages.phome.appAdvantageReporting" | translate }}</p>
            </div>
          </div>
          <!--Grid row-->



          <!--Grid row-->
          <div class="row ">
            <div class="col-2">
              <i class="fa fa-2x fa-magic green-text"></i>
            </div>
            <div class="col-10 mb-3">
              <h5 class="font-weight-bold mb-3 ">{{ "pages.phome.appAdvantage4Title" | translate }}</h5>
              <p >{{ "pages.phome.appAdvantageAdaptable" | translate }}</p>
            </div>
          </div>
          <!--Grid row-->

        </div>
        <!--Grid column-->

      </div>
    </div>

  </div>



  <!-- BANNER-7
         ============================================= -->
  <section id="banner-7" class="bg-fixed banner-section division "
        style="background-image: url(../../../../assets/img/Reseau.png);
        background-size: cover; background-position: center center; background-repeat: no-repeat;"
  >
    <div class="container">
      <div class="row">

        <!-- BANNER TEXT -->
        <div class="col-12">
          <div class="text-center">


            <p class="pt-3" style="color: white; line-height: 1.7;">

              {{"pages.phome.beingMemberPart0" | translate }} <br>

              {{"pages.phome.beingMemberPart1" | translate }} <br>
              {{"pages.phome.beingMemberPart2" | translate }} <br>
              {{"pages.phome.beingMemberPart3" | translate }} <br>
              {{"pages.phome.beingMemberPart4" | translate }}
            </p>
            <!-- <ul style="font-size: 20px;">
                    <li class="pt-2">Faciliter la gestion du tiers payant</li>
                    <li class="pt-2">Simplifier et sécuriser la prise en charge de vos patients</li>
                  </ul> -->
            <!-- Button -->
            <p class="pt-2">
              <a routerLink="/adhere" class=" btn btn-success text-center tra-white-hover mt-20">
                {{"pages.phome.joinNetworkCare" | translate }}
              </a>
            </p>

          </div>
        </div> <!-- END BANNER TEXT -->


      </div> <!-- End row -->
    </div> <!-- End container -->
  </section> <!-- END BANNER-7 -->






</section>
