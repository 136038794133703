<div class="panel panel-default m-5">
  <section class="content" >
      <h2 mat-dialog-title class="uppercase" style="text-align: center;"> {{'pages.conditionsGeneralesHc.titleH1' | translate}} </h2>
      <div style="max-height: 300px; overflow: auto;">
        <mat-dialog-content  class="mat-typography">

          <h3> {{'pages.conditionsGeneralesHc.titleH2' | translate}}</h3>

            <!-- <h4 style = "font-weight: bold;"> Préambule </h4> -->
            <p>
              {{'pages.conditionsGeneralesHc.description' | translate}}
            </p>

            <h4 style = "font-weight: bold;"> {{'pages.conditionsGeneralesHc.content.0.title' | translate}} </h4>
            <p>{{'pages.conditionsGeneralesHc.content.0.description' | translate}}</p>
            <!-- <p>Cette demande d’adhésion ne vaut pas acceptation pour participer au réseau. Gamma Concept S.A dispose d’un délai maximal de 3 jours ouvrés pour étudier la demande d’adhésion et prendre sa décision. Passé ce délai, la demande sera considérée comme acceptée, le professionnel de santé pourra contacter Gamma Concept SA pour demander l’installation du matériel.</p>
            <p>L’adhésion est valable pour la même durée que celle du contrat de licence.</p> -->

            <h4 style = "font-weight: bold;"> {{'pages.conditionsGeneralesHc.content.1.title' | translate}} </h4>
            <p>
              {{'pages.conditionsGeneralesHc.content.1.description' | translate}}
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.0' | translate}} <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.1' | translate}} <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.2' | translate}}<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.3' | translate}}<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.4' | translate}} <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.adhesionConditions.5' | translate}}
            <p>
              {{'pages.conditionsGeneralesHc.content.2.gammaConceptEngagement.0' | translate}}
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.gammaConceptEngagement.1' | translate}} <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.gammaConceptEngagement.2' | translate}}<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.gammaConceptEngagement.3' | translate}}<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{'pages.conditionsGeneralesHc.content.2.gammaConceptEngagement.4' | translate}}<br>
            </p>
            <p>
              {{'pages.conditionsGeneralesHc.content.2.maintenanceExclusions' | translate}}
              <br>
              {{'pages.conditionsGeneralesHc.content.2.remoteMaintenanceAvailability' | translate}}
            </p>
            <p>
              <strong> {{'pages.conditionsGeneralesHc.content.2.adhesionConditionsEnd' | translate}} </strong>.
              {{'pages.conditionsGeneralesHc.content.2.adhesionConditionsEnd2' | translate}}
            </p>

            <!-- <h4 style = "font-weight: bold;"> 3-	Etendue des droits d’utilisation </h4>
            <p> Le Service doit être utilisé : </p>
            <p>•	Conformément aux stipulations du présent contrat ;</p>
            <p>•	Pour les seuls besoins du client détenteur de la licence ou de ses affiliés, par ses salariés, à l’exclusion de tout tiers à son groupe ;</p>
            <p>•	Par un personnel autorisé qualifié qui aura préalablement suivi une formation adaptée à l’utilisation afin d’en obtenir les résultats désirés. </p>
            <p>L’utilisateur doit disposer d’un droit personnel d’utilisation de Teliya Santé  non-transférable. Toute utilisation non expressément autorisée par Teliya santé, constitue une contrefaçon qui sera sanctionnée. </p>
            <p>Il est notamment interdit de procéder à :</p>
            <p>•	Toute représentation, diffusion ou commercialisation de Teliya santé, que ce soit à titre gracieux ou onéreux ; </p>
            <p>•	Toute forme d'utilisation du service ou de la documentation fournies de quelque façon que ce soit aux fins de conception, réalisation, diffusion ou commercialisation d’un service ou d’un logiciel similaire, équivalent ou de substitution . </p>

            <h4 style = "font-weight: bold;"> 4-	Exploitation des données </h4>
            <p>L’utilisateur est seul responsable de la qualité, de la pertinence des données et contenus qu’il transmet aux fins d’utilisation. Il garantit en outre être titulaire des droits de propriété intellectuelle lui permettant d’utiliser les données et contenus. Plus généralement, l’utilisateur est seul responsable des contenus et messages diffusés et/ou téléchargés via Teliya santé. Il demeure le seul propriétaire des données qu’il a rentré ou saisi via les outils mis à disposition par Gamma concept SA. </p>
            <p>En conséquence le Gamma Concept SA dégage toute responsabilité en cas de non-conformité des données et/ou des contenus aux lois et règlements, à l’ordre public. L’utilisateur garantit Gamma Concept SA contre tout préjudice qui résulterait de sa mise en cause par un tiers pour une violation de cette garantie. </p>

            <h4 style = "font-weight: bold;"> 5-	Protection des données personnelles </h4>
            <p>Le Logiciel permet, de par sa conception, de stocker, traiter et communiquer des données personnelles en toute sécurité. Il revient au responsable du traitement de vérifier la licéité et la légitimité de ses collectes et traitement de données personnelles selon la législation applicable. </p>

            <h4 style = "font-weight: bold;">  6-	Responsabilité de l'utilisateur </h4>
            <p> L'utilisateur est seul responsable de la préservation de ses données. Gamma Concept SA décline toute responsabilité quant à la perte ou le vol du Logiciel, de ces composants ou des données de l'utilisateur. </p>

            <h4 style = "font-weight: bold;"> 7-	Assistance </h4>
            <p> Pour permettre la réalisation des prestations d’assistance, l’utilisateur s’engage notamment à : </p>
            <p> •	Se reporter à la documentation avant chaque demande d’intervention ; </p>
            <p> •	Mettre à disposition de Gamma concept SA toute information nécessaire demandée pour la compréhension et la résolution des anomalies et des dysfonctionnements rencontrés ; </p>
            <p> •	 Faciliter l’accès du personnel de Gamma concept SA à toutes ses installations si cela était nécessaire et à leur assurer un libre accès aux locaux ainsi qu’à lui indiquer un correspondant adéquat ; </p>
            <p> •	Installer et administrer ses équipements et applications non fournis par Gamma Concept SA, ainsi que ses réseaux. </p>

            <h4 style = "font-weight: bold;"> 8-	Sécurité des données </h4>
            <p>Chacune des parties s’engagent à mettre en œuvre les moyens techniques appropriés pour assurer la sécurité des données. Gamma Concept SA mettra en place les mesures techniques et organisationnelles de nature à empêcher tout accès ou utilisations fraudeuses des données et à prévenir toutes pertes, altérations et destructions des données. </p>

            <h4 style = "font-weight: bold;"> 9-	Propriété </h4>
            <p> L’utilisateur s’interdit de reproduire tout élément du logiciel, par quelque moyen que ce soit, sous quelque forme que ce soit et sur quelque support que ce soit hors documentation visant à la formation des utilisateurs du client disposant d’une licence. </p>

            <hr />
            <p>Version 1.0 <br/>
            Publié le 01/02/2021</p> -->

        </mat-dialog-content>

        </div>
  </section>
</div>
