<!--Intro Section-->
<br> <br>
<section class="view intro-2">
  <div class="mask rgba-gradient">
    <div class="container h-100 d-flex justify-content-center align-items-center">
      <div class="row flex-center pt-1">
        <div class="col-md-6 text-center text-md-left margins">

        </div>
        <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">

        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <h1 class="text-center pt-4" style="color: #033B55; font-size: 38px; font-weight: bold;">
            {{ "pages.cardTeliyaPage.healthCardTeliya.title" | translate }}
          </h1>
          <!-- <p class="text-center" style="color: white;">
            La carte Teliya santé est une carte biométirique rechargeable valable partout en Guinée. <br>
            Pratique et sécurisée, elle permet de garder une mémoire de votre santé et de financer vos soins de santé.
          </p> -->
        </div>
      </div>

      <!-- <p style="padding-top: 300px;">

        <a class="btn btn-success wow fadeInLeft text-white mt-2"  routerLink="/commander"
          data-wow-delay="0.3s">
          <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> Je commande ma carte
        </a>
      </p> -->
    </div>

  </div>

</section>

<section class="backcolor">
  <div>
    <div class="container">
      <div class="row text-center pt-4">
        <div class="col-md-5 col-lg-4 col-sm-12 mb-4">
          <img src="../../../../assets/img/carte-santé.png" width="300" height="175" alt="image carte de santé teliya">
        </div>
        <div class="col-md-6 col-sm-12 col-lg-7 offset-md-1">
          <p class="text-center mt-1">
            {{ "pages.cardTeliyaPage.healthCardTeliya.subtitle1" | translate }} <br>
            {{ "pages.cardTeliyaPage.healthCardTeliya.subtitle2" | translate }}
          </p>
          <p class="mt-2">

            <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/commander" data-wow-delay="0.3s">
              <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> {{ "pages.cardTeliyaPage.healthCardTeliya.commandCard" | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <section id="about-5" class="pt-100 about-section division"
    style="background-image: url(../../../../assets/img/carte2.png); background-repeat: no-repeat;
    background-position: center center; background-size: cover ; height: 500px;">
    <div class="container">
      <div class="row d-flex align-items-center">


        <div class="col-sm-12 col-md-7 col-xl-7 pt-4">
          <div class="row" >
            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-id-card fa-2x pr-2 bg" aria-hidden="true" ></span>
                <p class="size-18 pt-1 text-col">{{ "pages.cardTeliyaPage.aboutSection.content.0.text" | translate }} </p>
              </div>

            </div>
            <div class="col-md-6">

                        <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                          <span class="fa fa-medkit fa-2x pr-2 bg" aria-hidden="true"></span>

                          <p class="size-18 pt-1">{{ "pages.cardTeliyaPage.aboutSection.content.1.text" | translate }}</p>

                        </div>

            </div>

            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-folder-open-o fa-2x pr-2 bg" aria-hidden="true"></span>
                <p class="size-18 pt-1">{{ "pages.cardTeliyaPage.aboutSection.content.2.text" | translate }}</p>

              </div>

            </div>

            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-shield fa-2x pr-2 bg" aria-hidden="true"></span>
                <p class="size-18 pt-1">{{ "pages.cardTeliyaPage.aboutSection.content.3.text" | translate }}</p>

              </div>

            </div>

          </div>
        </div>

      </div> <!-- End row -->
    </div> <!-- End container -->
  </section> <!-- END ABOUT-5 -->

  <!-- <div style="background-image: url(../../../../assets/img/row-bgimage-4.jpg); height: 230px; color: white; text-align: center;">

  <div class="container text-center">
    <div class="mt-3">
      <h2 class="text-center size-30 pt-3">Rechargeable</h2>
    </div>
  <p class="text-center size-18">Faites financer vos frais de santé sans frais en rechargeant votre carte dépuis l'étranger et à travers Orange money Guinée. <br> Remettez simplement votre numéro de carte.</p>
  <a routerLink="/recharger" class="text-center btn btn-success btn-lg bot" role="button" aria-pressed="true">Recharger ma carte</a>

  </div>
  </div> -->
  <div class="pt-5" style="background-color: #f8f9fb; color: #f7f8fb">

  </div>
  <div style="background-image: url(../../../../assets/img/carte3.png); background-size: cover; background-position: center center;">
    <div class="container section-couveture ">
      <!-- <h2 class="text-center size-30 pt-3">Couverture des soins de santé</h2> -->
      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px;"> {{ "pages.cardTeliyaPage.healthCardTeliya.knowMore" | translate }} </h2>
      <hr>
      <br>
      

      <p >{{ "pages.cardTeliyaPage.healthCardTeliya.knowMoreContent.0" | translate }} </p><br>
      <p >{{ "pages.cardTeliyaPage.healthCardTeliya.knowMoreContent.1" | translate }}</p><br>

      <p  >{{ "pages.cardTeliyaPage.healthCardTeliya.knowMoreContent.2" | translate }} </p><br>
      <p > {{ "pages.cardTeliyaPage.healthCardTeliya.knowMoreContent.3" | translate }}</p><br>
      <p class="tite">
        <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/insurance"
          data-wow-delay="0.3s">
          <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> {{ "pages.cardTeliyaPage.healthCardTeliya.subscribeButton.text" | translate }}
        </a>
      </p>
      <br>

    </div>
  </div>

  <div
    style="background-color: #033b55; color: white; ">
    <div class="container section-couveture ">
      <div class="row text-center">
        <div class="col-sm-10 offset-sm-1">
          <h2 class="text-center size-33" style="font-size: 38px; padding-top: 30px; padding-bottom: 20px;">
            {{ "pages.cardTeliyaPage.healthWallet.title" | translate }}
          </h2>
          <p class="pt5" >
            {{ "pages.cardTeliyaPage.healthWallet.content" | translate }}
          </p>
          <p class="tite text-center">
            <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/recharger" data-wow-delay="0.3s">
              <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> {{ "pages.cardTeliyaPage.healthWallet.rechargeButton.text" | translate }}
            </a>
          </p>
          <br>
        </div>
      </div>


    </div>
  </div>


  <div style="background-color: #f8f9fb; color: black;">
    <div class="container section-dmp">

      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px;">{{ "pages.cardTeliyaPage.sharedMedicalRecord.title" | translate }}</h2>

   <p class="mb-2 line mb-4">{{ "pages.cardTeliyaPage.sharedMedicalRecord.content" | translate }}</p>


<div class="row">
  <!--Grid column-->
  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-medkit fa-2x pr-2 bg" aria-hidden="true" ></span>
      <p class="pt-2 text-col"> {{ "pages.cardTeliyaPage.sharedMedicalRecord.benefits.0" | translate }} </p>
    </div>

  </div>
  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
       <span class="fa fa-list-alt fa-2x pr-2 bg" aria-hidden="true"></span>

       <p class=" pt-2 text-col">{{ "pages.cardTeliyaPage.sharedMedicalRecord.benefits.1" | translate }}</p>

    </div>

  </div>

  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-files-o fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class="text-col pt-2">{{ "pages.cardTeliyaPage.sharedMedicalRecord.benefits.2" | translate }}</p>

    </div>

  </div>

  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-street-view fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class="text-col pt-2"> {{ "pages.cardTeliyaPage.sharedMedicalRecord.benefits.3" | translate }}</p>

    </div>

  </div>


  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-pie-chart fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class=" text-col pt-2">{{ "pages.cardTeliyaPage.sharedMedicalRecord.benefits.4" | translate }}</p>

    </div>

  </div>
</div>

<div class="row">
<div class="col-12">
  <p class="line mt-3 mb-4">
    {{ "pages.cardTeliyaPage.sharedMedicalRecord.accessControl" | translate }}
  </p>
</div>
</div>



</div>
</div>


  <div
    style="background-image: url(../../../../assets/img/carte6-prim.png); background-size: cover; background-position: center center; color: black;">
    <!-- <div style="height: 40px;"></div> -->
    <div class="row" style="background-color: white; opacity: 0.8;">



      <!-- TEXT BLOCK -->
      <div class="col-12">
        <div class="txt-block pc-30 wow fadeInUp" data-wow-delay="0.4s">

          <!-- Section ID -->
          <h2 class="text-center size-33"
            style="font-size: 38px; padding-top: 15px; line-height: 1.1em; color: #033b55;">
            {{ "pages.cardTeliyaPage.mobileApplication.title" | translate }}
          </h2>

          <p class="text-center pt-1">
            {{ "pages.cardTeliyaPage.mobileApplication.content.0" | translate }} <br>
            {{ "pages.cardTeliyaPage.mobileApplication.content.1" | translate }} <br>
            {{ "pages.cardTeliyaPage.mobileApplication.content.2" | translate }} <br>
            {{ "pages.cardTeliyaPage.mobileApplication.content.3" | translate }}
          </p>
          <p class="tite text-center">
            <a class="btn wow fadeInLeft text-whitemt-1" style="background-color: #033b55; color: white;" href="https://play.google.com/store/apps/details?id=health.teliya.net" target="_blank" data-wow-delay="0.3s">
              {{ "pages.cardTeliyaPage.mobileApplication.discoverButton.text" | translate }} 
            </a>
          </p>

        </div>
        <!-- END TEXT BLOCK -->
      </div>

    </div>

  </div>

</section>
