import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

// import 'leaflet';
// declare let L;
import * as L from 'leaflet';
// import 'beautifymarker';
// import "beautifymarker/leaflet-beautify-marker-icon.css";
// import "beautifymarker/leaflet-beautify-marker-icon.js";
// import countries from './countries.js';
import 'mapbox-gl-leaflet';
import 'leaflet-mouse-position';
import { GlobalService } from 'src/app/services/global.service';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class  MapComponent implements OnInit {



// Initialisation du paginatior
@ViewChild(MatPaginator) paginator: MatPaginator;

  latitude: number = 9.576713999999999;
  longitude: number = -13.6640021;
  partner: any;
  // google maps zoom level
  map = false;
  zoom: number = 12;

  // Définition du nombre d'éléments par page
  pageSize = 10;

  // Variable pour stocker la longueur totale des données
  totalLength: number;

  // Tableau des partenaires à afficher sur la page actuelle
  displayedPartners: any[] = [];

  // Tableau contenant tous les partenaires récupérés
  partners: any[] = [];

  // Options pour la taille de la page
  pageSizeOptions = [5, 10, 25, 100];

  //-----------------------
  partnersMarker: marker[] = [];
  clinics: any = [];
  totalLengthCli: number;
  displayedClinics: any[] = [];

  laboratories: any = [];
  totalLengthLab: number;
  displayedLab: any[] = [];


  pharmacies: any = [];
  totalLengthPharm: number;
  displayedPharm: any[] = [];


  showpopup = 'none';
  appointment: any;
  partnerClicked: any;
  user: any;
  map_ = document.getElementById('map2');
  map2: any;
  display_map = false;
  selectedIndex: number = 0;
  activeCountry = 'Guinea';
  partnersCg: any = [];
  partnersGn: any = [];
  countries = [
    { name: 'Guinee', value: 'Guinea' },
    { name: 'Congo', value: 'Congo' },
  ];
  constructor(
    public svcGlobal: GlobalService,
    public orderService: OrderService,
    private locationStrategy: LocationStrategy,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.svcGlobal.setTitle('Réseau de Soins');
  }

  ngOnInit(): void {
    this.svcGlobal.fixDisplay();
    this.user = this.svcGlobal.connectedUser;
    this.getPartnersGn();
    this.getPartnersCg();
    // this.latitude = (this.partner && this.partner.lat != null) ? +this.partner.lat : 9.576713999999999;
    // this.longitude = (this.partner && this.partner.lng != null) ? +this.partner.lng : -13.6640021;
    // this.map2 = L.map('map2').setView([this.latitude, this.longitude], 9);
    // this.otherMap();
    // this.geolocatePharmacy();
  }

  /**
   * get all partners
   */
  getPartnersGn() {
    let is_grab_point = false;
    this.svcGlobal.showNgxLoader();
    this.orderService.getCardGrabPoints(is_grab_point).subscribe(
      (res) => {
        this.partnersGn = res['data'];
        this.partners = this.partnersGn;
        this.changeDetectorRef.detectChanges();
        // this.svcGlobal.initDataTables('#tbPartners', 10, 0, 'desc');
        this.getElements(this.partners);
        this.svcGlobal.spinner.hide();
        this.totalLength = this.partners.length;

        // Mettre à jour les partenaires affichés
        this.updateDisplayedPartners();

      },
      (error) => {
        // console.log(error);
        this.svcGlobal.spinner.hide();
        this.toastr.error(
          this.translateService.instant('operationFailure'), 
          'OOPS', 
          {
          timeOut: 4000,
          progressBar: true,
        });
      }
    );
  }

  
  getPartnersCg() {
    let is_grab_point = false;
    this.svcGlobal.showNgxLoader();
    this.orderService.getCardGrabPointsCg(is_grab_point).subscribe(
      (res) => {
        this.partnersCg = res['data'];
        this.changeDetectorRef.detectChanges();
        // this.svcGlobal.initDataTables('#tbPartners', 10, 0, 'desc');
        this.svcGlobal.spinner.hide();
        if (this.activeCountry != 'Guinea') {
          this.partners = this.partnersCg;
          this.getElements(this.partnersCg);

          this.totalLength = this.partners.length;

          // Mettre à jour les partenaires affichés
          this.updateDisplayedPartners();
        }
      },
      (error) => {
        // console.log(error);
        this.svcGlobal.spinner.hide();
        this.toastr.error(
          this.translateService.instant('operationFailure'), 
          'OOPS', 
          {
          timeOut: 4000,
          progressBar: true,
        });
      }
    );
  }

   // Mettre à jour les partenaires affichés sur la page actuelle
   updateDisplayedPartners() {
    // Calculer l'index de départ et de fin des partenaires à afficher
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    // Extraire les partenaires à afficher à partir du tableau complet des partenaires
    this.displayedPartners = this.partners.slice(startIndex, endIndex);
    this.changeDetectorRef.detectChanges();

  }


  // Fonction appelée lors d'un changement de page
  onPageChange(event: PageEvent) {
    // Mettre à jour la taille de la page
    this.pageSize = event.pageSize;
    // Mettre à jour les partenaires affichés
    this.updateDisplayedPartners();
    this.changeDetectorRef.detectChanges();
  }


  back() {
    this.locationStrategy.back();
  }
  getElements(partners) {
    this.svcGlobal.showNgxLoader();
    this.clinics = [];
    this.laboratories = [];
    this.pharmacies = [];
    for (let part of partners) {
      if (part.type == 'clinic') {
        this.clinics.push(part);
      }
      if (part.type == 'laboratory') {
        this.laboratories.push(part);
      }
      if (part.type == 'pharmacy') {
        this.pharmacies.push(part);
      }
    }

    this.svcGlobal.spinner.hide();
  }
  

  countryChanged(event) {
    if (event?.target?.value == 'Guinea') {
      this.partners = this.partnersGn;
    } else {
      this.partners = this.partnersCg;
    }
    this.activeCountry = event?.target?.value;
    this.getElements(this.partners);
  }
  toggle() {
    this.display_map = !this.display_map;
  }



  // geolocatePharmacy() {
  //   // this.svcGlobal.showLoading();
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: Position) => {
  //    if (position) {
  //      this.latitude = position.coords.latitude;
  //      this.longitude = position.coords.longitude;
  //      console.log('Latitude: ' + this.latitude + ' et Longitude ' + this.longitude);
  //     //  this.partnersMarker.push({
  //     //   lat: position.coords.latitude,
  //     //   lng: position.coords.longitude,
  //     //   label: 'PH',
  //     //   draggable: false
  //     // });
  //     //  this.svcGlobal.hideLoading();
  //    } else {
  //     // this.svcGlobal.hideLoading();
  //     Swal.fire({
  //       position: 'center',
  //       type: 'error',
  //       title: 'Impossible d\'obtenier les coordonnees gps ,veillez verifier la connexion internet',
  //       showConfirmButton: false,
  //       timer: 2000
  //     });
  //     position = null;
  //     return 0;
  //    }
  //       },
  //       (error: PositionError) => {
  //         //  this.svcGlobal.hideLoading();
  //      }
  //      );
  //    }
  // }
  // clickedMarker(partner: any, label: string, index: number) {
  //   console.log(`clicked the marker: ${label || index}`);
  //   if (this.svcGlobal.connectedUser.insured) {
  //     this.partnerClicked = partner;
  //     // this.showpopup = 'block';
  //     // this.openDialog();
  //   }
  // }
  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   console.log('dragEnd', m, $event);
  // }
  // openDialog(): void {
  //   // let partner = null;
  //   // const dialogRef = this.dialog.open(AddAppointmentComponent, {
  //   //   width: '700px',
  //   //   data: {
  //   //     insured: this.svcGlobal.connectedUser.insured,
  //   //     partner: partner,
  //   //   }
  //   // });

  //   // dialogRef.afterClosed().subscribe(result => {
  //   //   console.log('Retour dialogue --> map');
  //   //   console.log(result);
  //   //   this.appointment = result;
  //   // });
  //   this.router.navigate(['programme']);
  // }
  // onStartAppointmentfromMap(partner: any) {
  //   this.partnerClicked = partner;
  //   const dialogRef = this.dialog.open(AddAppointmentComponent, {
  //     width: '700px',
  //     data: {
  //       insured: this.svcGlobal.connectedUser.insured,
  //       partner: (this.partnerClicked && this.partnerClicked != null) ? this.partnerClicked : null,
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('Retour dialogue --> map');
  //     console.log(result);
  //     this.appointment = result;
  //   });
  // }

  // otherMap() {
  //   L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
  //         attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //       }).addTo(this.map2);
  //       this.partners.map(ptr => {
  //         if (ptr.lat && ptr.lat) {
  //           // L.marker([ptr.lat, ptr.lng], {
  //           //   icon: L.BeautifyIcon.icon(),
  //           //   // draggable: true
  //           // }).addTo(this.map2).bindPopup(ptr.name);

  //           // var options = {
  //           //   icon: 'bus',
  //           //   borderColor: '#b3334f',
  //           //   textColor: '#b3334f'
  //           // };
  //           // tslint:disable-next-line: triple-equals
  //           let name;
  //           let icon;
  //           let options;
  //           if (ptr.type === 'clinic') {
  //             name = 'Clinique: ' + ptr.name;
  //             icon = 'ambulance';
  //             // Icon
  //             const hIcon = L.divIcon({
  //               className: 'custom-div-icon',
  //               html: '<div style=\'background-color:#1e8bc3;\' class="marker-pin"></div><i class="material-icons">local_hospital</i>',
  //               iconSize: [30, 42],
  //               iconAnchor: [15, 42]
  //             });
  //           } else if (ptr.type === 'laboratory') {
  //             name = 'Laboratoire: ' + ptr.name;
  //             icon = 'stethoscope';
  //             // Icon
  //             const hIcon = L.divIcon({
  //               className: 'custom-div-icon',
  //               html: '<div style=\'background-color:#1e8bc3;\' class="marker-pin"></div><i class="material-icons">local_hospital</i>',
  //               iconSize: [30, 42],
  //               iconAnchor: [15, 42]
  //             });
  //           }if (ptr.type === 'pharmacy') {
  //             name = 'Pharmacie: ' + ptr.name;
  //             icon = 'hospital-o';
  //             // Icon pharmacy
  //             const hIcon = L.divIcon({
  //               className: 'custom-div-icon',
  //               html: '<div style=\'background-color:green;\' class="marker-pin"></div><i class="material-icons">local_pharmacy</i>',
  //               iconSize: [30, 42],
  //               iconAnchor: [15, 42]
  //             });
  //           }
  //           options = {
  //             icon: icon,
  //             iconShape: 'marker',
  //             // borderColor: '#8D208B',
  //             // textColor: '#8D208B',
  //             // backgroundColor: 'transparent'
  //           };
  //           const lIcon = L.divIcon({
  //             className: 'custom-div-icon',
  //             html: '<div style=\'background-color:green;\' class="marker-pin"></div><i class="material-icons">local_pharmacy</i>',
  //             iconSize: [30, 42],
  //             iconAnchor: [15, 42]
  //           });
  //           L.marker([ptr.lat, ptr.lng], {
  //             // icon: L.BeautifyIcon.icon(options),
  //             icon: lIcon,
  //             // draggable: true
  //           }).addTo(this.map2).bindPopup("popup").bindPopup(name);
  //         }
  //       });
  // }


}


// just an interface for type safety.
interface marker {
  id?: number;
  lat?: number;
  lng?: number;
  label?: string;
  name?: string;
  address?: string;
  type?: string;
  phone?: string;
  color?: string;
  draggable?: boolean;
  icon?: string;
}
