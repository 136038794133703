import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit-insurance-data',
  templateUrl: './edit-insurance-data.component.html',
  styleUrls: ['./edit-insurance-data.component.css'],
  providers: [DatePipe]
})
export class EditInsuranceDataComponent implements OnInit {

  items:any;
  userform: FormGroup;
  id: null;
  todayDate = new Date();
  maxDate: string;
  maxAvailableDate: Date;

  HE: any = [
    {value: "A"},
    {value: "B"},
    {value: "C"},
    {value: "D"},
    {value: "E"},
    {value: "Aucun"}
  ];

  gender: any = [
    { value: "male" },
    { value: "female"},
  ];
  RELATION: any = [
    { value: "spouse" },
    { value: "child" },
    // {value: "Autre"}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, private dialogRef: MatDialogRef<EditInsuranceDataComponent>,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    private globalSvc: GlobalService,
    ) {
      this.dateAdapter.setLocale('fr');
      this.items = this.data
      if(this.items.id){
        this.id = this.items.id;
      }
    }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    //this.items = this.data
    // console.log(this.data)
    this.setMaxAvailableDate();
    this.items = this.data
    console.log(this.items);
    
    this.initForm();
  }

  setMaxAvailableDate(){
    this.maxDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
    const currentYear = new Date().getFullYear();
    const currentMonth = +this.maxDate.substring(0,2);
    const currentDay = +this.maxDate.substring(3,5);
    this.maxAvailableDate = new Date(currentYear, currentMonth-1 ,currentDay);
  }

  initForm() {

    this.userform = this.fb.group({
      index: new FormControl(this.items.index),
      // id: new FormControl(this.id),
      firstname: new FormControl(this.items.firstname, [Validators.required, Validators.maxLength(50)]),
      lastname: new FormControl(this.items.lastname, [Validators.required, Validators.maxLength(30)]),
      email: new FormControl(this.items.email,Validators.email),
      hasCard: new FormControl(this.items.hasCard),
      hasMedical: new FormControl(this.items.hasMedical),
      phone: new FormControl(this.items.phone, Validators.required),
      gender: new FormControl(this.items.gender, Validators.required),
      relationship: new FormControl(this.items.relationship, Validators.required),
      birthday:new FormControl(this.items.birthday, [Validators.required]),
      address:new FormControl(this.items.address,  Validators.maxLength(100)),
      // g_sanguin: new FormControl(this.items.g_sanguin),
      // tensionCtrl: new FormControl(this.items.tensionCtrl),
      // diabeteCtrl: new FormControl(this.items.diabeteCtrl),
      // hepatie: new FormControl(this.items.hepatie),
      // other_maladie: new FormControl(this.items.other_maladie),
      // allergie: new FormControl(this.items.allergie),

    });
  }


  onUpdate(){
      this.dialogRef.close(this.userform.value);
  }

  close() {
      this.dialogRef.close();
  }

}
