import { BrowserModule } from '@angular/platform-browser';
import { NgModule , CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './views/pages/home/home.component';
import { AboutComponent } from './views/pages/about/about.component';
import { ContactComponent } from './views/pages/contact/contact.component';
import { ErrorComponent } from './views/pages/error/error.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RechargerComponent } from './views/pages/recharger/recharger.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { CheckoutComponent } from './views/pages/checkout/checkout.component';
import { BetweenUsComponent } from './views/pages/between-us/between-us.component';
import { AssurezvousComponent } from './views/pages/assurezvous/assurezvous.component';
import { AdhereComponent } from './views/pages/adhere/adhere.component';
import { OfferpartComponent } from './views/pages/offerpart/offerpart.component';
import { OfferproComponent } from './views/pages/offerpro/offerpro.component';
import { HeaderComponent } from './views/partials/header/header.component';
import { FooterComponent } from './views/partials/footer/footer.component';
import { CustserviceComponent } from './views/partials/custservice/custservice.component';
import { SliderComponent } from './views/partials/slider/slider.component';
import { ChangePasswordComponent } from './views/pages/change-password/change-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatBadgeModule} from "@angular/material/badge";
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
// import {MatDatepickerModule} from "@angular/material/datepicker"
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatStepperModule} from '@angular/material/stepper';
import { MatTreeModule } from '@angular/material/tree';
import { EditDataComponent } from './views/pages/edit-data/edit-data.component';
import { LoadingComponent } from './views/partials/loading/loading.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { CancelComponent } from './views/pages/cancel/cancel.component';
import { NotificationModule } from './notification.module';
import { NotificationService } from './services/notification.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { OmCheckStatusComponent } from './pages/om-check-status/om-check-status.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ConditionsComponent } from './views/pages/conditions/conditions.component';
import { SafePipeModule } from 'safe-pipe';
import { VisapayComponent } from './views/pages/visapay/visapay.component';
import { OrderComponent } from './views/pages/order/order.component';
import { SubscribeInsuranceComponent } from './views/pages/subscribe-insurance/subscribe-insurance.component';
import { InsuranceComponent } from './views/pages/insurance/insurance.component';
import { InsuranceNotifComponent } from './views/pages/insurance-notif/insurance-notif.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HealthcareNetworkComponent } from './views/pages/healthcare-network/healthcare-network.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapComponent } from './pages/map/map.component';
import { LeafletMapComponent } from './pages/leaflet-map/leaflet-map.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { OrderConditionsComponent } from './pages/order-conditions/order-conditions.component';
import { EditInsuranceDataComponent } from './views/pages/subscribe-insurance/edit-insurance-data/edit-insurance-data.component';
import { OrderConfirmationComponent } from './views/pages/order-confirmation/order-confirmation.component';
import { FormArrayExempleComponent } from './pages/form-array-exemple/form-array-exemple.component';
import { InsuranceOffersComponent } from './pages/insurance-offers/insurance-offers.component';
import { AllInsuranceOffersComponent } from './pages/all-insurance-offers/all-insurance-offers.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader'; 
import {TranslateLoader, TranslateModule } from '@ngx-translate/core';


// import { LeafletMapComponent } from './pages/leaflet-map/leaflet-map.component';
// import { MapComponent } from './pages/map/map.component';

export function HttpLoaderFactory (http: HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ErrorComponent,
    LoginComponent,
    RechargerComponent,
    RegisterComponent,
    CheckoutComponent,
    BetweenUsComponent,
    AssurezvousComponent,
    AdhereComponent,
    OfferpartComponent,
    OfferproComponent,
    HeaderComponent,
    FooterComponent,
    CustserviceComponent,
    SliderComponent,
    ChangePasswordComponent,
    EditDataComponent,
    EditInsuranceDataComponent,
    LoadingComponent,
    CancelComponent,
    OmCheckStatusComponent,
    ConditionsComponent,
    VisapayComponent,
    OrderComponent,
    SubscribeInsuranceComponent,
    InsuranceComponent,
    InsuranceNotifComponent,
    HealthcareNetworkComponent,
    MapComponent,
    LeafletMapComponent,
    PrivacyPolicyComponent,
    OrderConditionsComponent,
    OrderConfirmationComponent,
    FormArrayExempleComponent,
    InsuranceOffersComponent,
    AllInsuranceOffersComponent
  ],
  imports: [
    BrowserModule,
    SafePipeModule,
    MatPaginatorModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ff0000',
      secondaryColour: '#ffd700',
      tertiaryColour: '#00c957'
    }),
    
    AppRoutingModule,NgbModule,MatStepperModule,SnotifyModule,
    BrowserAnimationsModule,NotificationModule,
    MatSelectModule,HttpClientModule, FormsModule,ReactiveFormsModule,
    MatDatepickerModule,MatNativeDateModule,MatInputModule,MatRadioModule,MatCheckboxModule,
    MatFormFieldModule,MatDialogModule,MatGridListModule,
    MatButtonModule, MatTreeModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    NgxSpinnerModule,
    LeafletModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient,]
      }
    }),
  ],
  exports: [
    LoadingComponent,
    LeafletMapComponent
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ChangePasswordComponent,  OrderConditionsComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
