<div class="pb-4">
    <app-header></app-header>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="line-scale-party"
>
  <p style="font-size: 20px; color: white;">Veuillez patienter...</p>
</ngx-spinner>
<router-outlet></router-outlet>
<app-footer></app-footer>
