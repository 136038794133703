<!-- <div class="row content-heada">
  <div class="col-md-6">
    <p class="content-title">Gestion des Partenaires</p>
  </div>
  <div class="col-md-6 " style="text-align: right;">
    <ol class="breadcrumb">
      <li><a href="#">Partenaire</a></li> -->
      <!-- <li><a href="#">Carte</a></li> -->
      <!-- <li class="active">Carte</li>
    </ol>
  </div>
</div> -->
<!--
<div class="panel panel-default">
  <div class="panel-body">
    <button class="btn btn-default" (click)="back()"> <i class="fa fa-arrow-left"></i> Retour</button>
    <button *ngIf="user?.user_app == 'insured' || user?.user_app == 'subcriber'" class="btn btn-primary" (click)="openDialog(null)"> <i class="fa fa-arrow-left"></i> Ajouter un rendez-vous</button>
  </div>
</div> -->
<!-- <div *ngIf="map" class="row">
    <strong>La liste des partenaires sur une carte</strong>
    <agm-map
        [latitude]="latitude"
        [longitude]="longitude"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        [zoomControl]="false">

        <agm-marker
            *ngFor="let marker of partnersMarker; let i = index"
            (markerClick)="clickedMarker(marker, marker.label, i)"
            [latitude]="marker.lat"
            [longitude]="marker.lng"
            [label]="marker.label"
            [markerDraggable]="marker.draggable"
            [iconUrl]="marker.icon"
            (dragEnd)="markerDragEnd(marker, $event)">

          <agm-info-window>
            <h4>{{marker?.name}}</h4>
            <span>{{marker?.address}}</span> <br>
            <a *ngIf="(user?.user_app == 'insured' || user?.user_app == 'subcriber') && marker?.type !== 'pharmacy'" class="pointer" (click)="onStartAppointmentfromMap(marker)">Prendre rendez-vous</a>
          </agm-info-window>
        </agm-marker>

        <agm-circle [latitude]="latitude" [longitude]="longitude"
            [radius]="5000"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false">
        </agm-circle>

      </agm-map>
</div> -->

<div class="col-sm-4 pull-right py-3">
  {{ "country" | translate }}
  <select (change)="countryChanged($event)" [disabled]="display_map">
    <option *ngFor="let country of countries" [value]="country?.value">{{country.name}}</option>
  </select>
</div>

<div class="container">
  <div class="panel panel-default py-3">
    <div class="panel-heading">
      <h3 class="panel-title text-center" > {{ "pages.pourpartner.table.tableTitle" | translate }} | <a class="pointer text-success font-weight-bold" (click)="toggle()">{{ (!display_map) ?('pages.pourpartner.table.inCase1' | translate) : ('pages.pourpartner.table.inCase2' | translate)}}</a></h3>
    </div>
    <div class="panel-body">
      <div *ngIf="display_map" class="table-responsive">
         <!-- <div id="map2"></div> -->
         <!-- <div id="map2" style="height:500px;"></div> -->
         <app-leaflet-map [country]="activeCountry"></app-leaflet-map>
      </div>
      <div [hidden]="display_map" class="table-responsive ml-2 mr-2">
        <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex" mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label>
              <span matBadge="{{partners?.length}}" class="btn-sm btn-success" matBadgeOverlap="false">
                {{ "pages.pourpartner.table.allPartner" | translate }} 
              </span>
            </ng-template>
            <table id="tbPartners" class="table table-hover" style="margin-top: 20px;">
              <thead>
                <tr class="">
                  <th>{{ "pages.pourpartner.table.columnName" | translate }} </th>
                  <th>{{ "pages.pourpartner.table.columnAddress" | translate }}</th>
                  <th>{{ "pages.pourpartner.table.columnTel" | translate }}</th>
                  <!-- <th>Contact</th>
                  <th>Statut</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let partner of displayedPartners">
                  <td class="uppercase">{{partner?.name}}</td>
                  <td>{{partner?.address}}</td>
                  <td>{{partner?.phone}}</td>
                  <!-- <td class="uppercase">{{partner?.contactname}}</td>
                  <td class="uppercase">
                    <span class="label" [ngClass]="{'label-warning': partner?.status === 'inactif','label-success': partner.status === 'actif'}">{{partner.status}}</span></td> -->
                </tr>
              </tbody>
            </table>
            <mat-paginator [length]="totalLength"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="onPageChange($event)"
               showFirstLastButtons
               aria-label="Select page of periodic elements">
            </mat-paginator>
          </mat-tab>
          <mat-tab label="Cliniques({{clinics?.length}})">
            <ng-template mat-tab-label>
              <span matBadge="{{clinics?.length}}" class="btn-sm btn-success" matBadgeColor="primary" matBadgeOverlap="false">
                {{ "pages.pourpartner.table.clinicPartner" | translate }} 
              </span>
            </ng-template>
            <table id="tbPartners" class="table table-hover" style="margin-top: 20px;">
              <thead>
                <tr>
                  <th>{{ "pages.pourpartner.table.columnName" | translate }} </th>
                  <th>{{ "pages.pourpartner.table.columnAddress" | translate }}</th>
                  <th>{{ "pages.pourpartner.table.columnTel" | translate }}</th>
                  <!-- <th>Contact</th>
                  <th>Statut</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let clinic of clinics">
                  <td class="uppercase">{{clinic?.name}}</td>
                  <td>{{clinic?.address}}</td>
                  <td>{{clinic?.phone}}</td>
                  <!-- <td class="uppercase">{{partner?.contactname}}</td>
                  <td class="uppercase">
                    <span class="label" [ngClass]="{'label-warning': partner?.status === 'inactif','label-success': partner.status === 'actif'}">{{partner.status}}</span></td> -->
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <span matBadge="{{laboratories?.length}}" class="btn-sm btn-success" matBadgeColor="primary" matBadgeOverlap="false">
                {{ "pages.pourpartner.table.laboPartner" | translate }}
              </span>
            </ng-template>
            <table id="tbPartners2" class="table table-hover" style="margin-top: 20px;">
              <thead>
                <tr>
                  <th>{{ "pages.pourpartner.table.columnName" | translate }} </th>
                  <th>{{ "pages.pourpartner.table.columnAddress" | translate }}</th>
                  <th>{{ "pages.pourpartner.table.columnTel" | translate }}</th>
                  <!-- <th>Contact</th>
                  <th>Statut</th> -->
                </tr>
              </thead>
              <tbody class="text-center">
                <tr *ngFor="let labo of laboratories">
                  <td class="uppercase">{{labo?.name}}</td>
                  <td>{{labo?.address}}</td>
                  <td>{{labo?.phone}}</td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <span matBadge="{{pharmacies?.length}}" class="btn-sm btn-success" matBadgeColor="primary" matBadgeOverlap="false">
                {{ "pages.pourpartner.table.pharmacyPartner" | translate }} 
              </span>
            </ng-template>
            <table id="tbPartners3" class="table table-hover" style="margin-top: 20px;">
              <thead>
                <tr>
                  <th>{{ "pages.pourpartner.table.columnName" | translate }} </th>
                  <th>{{ "pages.pourpartner.table.columnAddress" | translate }}</th>
                  <th>{{ "pages.pourpartner.table.columnTel" | translate }}</th>
                  <!-- <th>Contact</th>
                  <th>Statut</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pharmacy of pharmacies">
                  <td class="uppercase">{{pharmacy?.name}}</td>
                  <td>{{pharmacy?.address}}</td>
                  <td>{{pharmacy?.phone}}</td>
                </tr>
              </tbody>
            </table>
          </mat-tab>
        </mat-tab-group>


        



  
        <!-- <table id="tbPartners" class="table table-striped table-hover" style="margin-top: 20px;">
          <thead>
            <tr>
              <th>Structure</th>
              <th>Nom</th>
              <th>Adresse</th>
              <th>Telephone</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let partner of partners">
              <td class="uppercase">{{partner?.type}}</td>
              <td class="uppercase">{{partner?.name}}</td>
              <td>{{partner?.address}}</td>
              <td>{{partner?.phone}}</td>
            </tr>
          </tbody>
        </table> -->
     </div>
    </div>
  </div>
</div>

