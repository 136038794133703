<nav class="navbar navbar-light navbar-expand-md fixed-top scrolling-navbar bgcolor pb-2"
  style="font-family: 'Kanit', sans-serif; font-size: 1rem; ">
  <div class="container-fluid row">
    <div class="col-md-2 col-lg-2">      
      <a class="navbar-brand" routerLink="/"> <img src="../../../../assets/img/icon/logo.jpg" class="rounded float-start"
          height="80" alt="logo" style="margin-top: -5px"> 
      </a>
    </div>
    <div class="col-md-10 col-lg-10 pull-left">
      <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse"
        data-target="#navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
        aria-controls="navbarSupportedContent">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01" [ngClass]="{ 'show': navbarOpen }">
  
        <ul class="nav navbar-nav ml-auto mr-4">
          <li class="nav-item" routerLinkActive="active ">
            <a class="nav-link" (click)="toggleNavbar()" routerLink='/home'> {{"navbar.home" | translate }} <span class="sr-only">(current)</span>
            </a>
          </li>
  
          <li class="nav-item dropdown" mdbDropdown>
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"> {{"navbar.individuals" | translate }}
            </a>
            <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
  
              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/the-card">
                {{"navbar.individualsMenu.card" |translate }}
              </a>

              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/commander">
                 {{ "navbar.individualsMenu.commandCard" | translate }}
              </a>
  
              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/recharger">
                {{ "navbar.individualsMenu.rechargeCard" | translate }}
              </a>
        
              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/insurance">
                {{ "navbar.individualsMenu.HealthInsurance" | translate }}
              </a>
            </div>
          </li>
  
          <li class="nav-item dropdown " mdbDropdown>
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"> 
               {{ "navbar.healthcareProfessional" | translate }}
            </a>
            <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
  
              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/reseau-de-soin">
                {{ "navbar.healthcareProfessionalMenu.teliyaProfessionals" | translate }}
              </a>
              <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/adhere">
                {{ "navbar.healthcareProfessionalMenu.joinUs" | translate }}
              </a>
            </div>
          </li>
  
          <li class="nav-item " routerLinkActive="active ">
            <a class="nav-link" (click)="toggleNavbar()" routerLink="/our-partners">
              {{"navbar.healthcareNetwork" | translate }}
            </a>
          </li>
  
          <li class="nav-item " routerLinkActive="active ">
            <a class="nav-link" (click)="toggleNavbar()" routerLink="/about">
              {{"navbar.about" | translate }}
            </a>
          </li>
  
          <li class="nav-item" routerLinkActive="active ">
            <a class="nav-link" (click)="toggleNavbar()" routerLink="/contact"> {{"navbar.contact" | translate }}</a>
          </li>
          <li class="nav-item dropdown" mdbDropdown>
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">  {{ "language" | translate }} ({{altCountry}})
              <span class="sr-only"> Français</span></a>
            <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">

              <a class="dropdown-item" (click)="switchLanguage('fr')">
                <!-- country flag
                  <img src="../../../../assets/img/{{countryFlag}}" [alt]="altCountry" width="18" height="12">
                -->
                {{country1 | translate }}
              </a>
              <a class="dropdown-item" (click)="switchLanguage('en')">
                <!-- country2 flaggeLanguage(country2)
                  <img src="../../../../assets/img/{{countryFlag2}}" [alt]="altCountry2" width="18" height="12">
                -->
                {{country2 | translate}}
              </a>


              <a class="dropdown-item" (click)="switchLanguage('es')">
                <!-- country2 flaggeLanguage(country2)
                  <img src="../../../../assets/img/{{countryFlag2}}" [alt]="altCountry2" width="18" height="12">
                -->
                {{country3 | translate}}
              </a>
  
              <!-- 
              <a class="dropdown-item" (click)="onChangeLanguage(country1)">
                 country flag
                  <img src="../../../../assets/img/{{countryFlag}}" [alt]="altCountry" width="18" height="12">
                
                {{country1}}
              </a>
              <a class="dropdown-item" (click)="onChangeLanguage(country2)">
                 country2 flag
                  <img src="../../../../assets/img/{{countryFlag2}}" [alt]="altCountry2" width="18" height="12">
                
                {{country2}}
              </a>
              -->
  
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>