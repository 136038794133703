import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentCountry: any="France";
  selectedFlag: any
  altCountry: any ='fr';
  altCountry2: any ='en';
  altCountry3: any ='es';
  countryFlag: any = 'france-flag.jpg';
  countryFlag2: any = 'united-kingdom-flag.jpg';
  country1: string = "Français" ;
  country2: string = "Anglais" ;
  country3: string = "Espagnol" ;

  

  constructor(public route: Router,
     public authService: AuthService,
     private matDialog: MatDialog,
     public globalSvc: GlobalService,
     private appComponent: AppComponent
     ) {}

     switchLanguage(language: string) {

      if (language == 'fr') {
        this.globalSvc.changePreferedLanguage('Français');
        this.currentCountry = "France";
        this.selectedFlag = 'france-flag.jpg';
        this.countryFlag = 'france-flag.jpg';
        this.countryFlag2 = 'united-kingdom-flag.jpg';
        this.altCountry = 'fr';
        this.altCountry2 = 'en';
        this.altCountry3 = 'es';
        this.country1 = "Français";
        this.country2 = "Anglais";
        this.country3 = "Espagnol";
      } else if (language == 'es') {
        this.globalSvc.changePreferedLanguage('Español');
        this.selectedFlag = 'spain-flag.jpg';
        this.currentCountry = "Espagne";
        this.countryFlag = 'spain-flag.jpg';
        this.countryFlag2 = 'united-kingdom-flag.jpg';
        this.altCountry = 'es';
        this.altCountry2 = 'en';
        this.altCountry3 = 'fr';
        this.country1 = "Francés";
        this.country2 = "Inglés";
        this.country3 = "Español";
      } else {
        this.globalSvc.changePreferedLanguage('English');
        this.selectedFlag = 'united-kingdom-flag.jpg';
        this.currentCountry = "England";
        this.countryFlag = 'united-kingdom-flag.jpg';
        this.countryFlag2 = 'france-flag.jpg';
        this.altCountry = 'en';
        this.altCountry2 = 'fr';
        this.altCountry3 = 'es';
        this.country1 = "French";
        this.country2 = "English";
        this.country3 = "Spanish";
      }
      

      this.appComponent.switchLanguage(language);
    }


  

  ngOnInit(): void {
    //this.checkCountry();
    this.globalSvc.fixDisplay();
  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  onChangePassword(){
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";

    const modalDialog = this.matDialog.open(ChangePasswordComponent, dialogConfig);
  }



  onLogOut(){
    this.authService.logout();
    this.route.navigate(['/login']);
  }
  
  /** onChangeLanguage(lang){
    if(lang == 'Français'){
      this.globalSvc.changePreferedLanguage('Français');
      this.currentCountry = "France";
      this.selectedFlag = 'france-flag.jpg'
      this.countryFlag = 'france-flag.jpg';
      this.countryFlag2 = 'united-kingdom-flag.jpg';
      this.altCountry = 'fr'
      this.altCountry2 = 'en'
      this.country1="Français";
      this.country2="English";
    }else{
      this.globalSvc.changePreferedLanguage('Anglais');
      this.selectedFlag = 'united-kingdom-flag.jpg';
      this.currentCountry = "England";
      this.countryFlag = 'united-kingdom-flag.jpg';
      this.countryFlag2 = 'france-flag.jpg';
      this.altCountry = 'en'
      this.altCountry2 = 'fr'
      this.country1="English";
      this.country2="Français";
    }
    console.log('changed'); 

  }cmd
  checkCountry(){
    (this.currentCountry =="France")? this.selectedFlag = 'france-flag.jpg' : this.selectedFlag = 'united-kingdom-flag.jpg';
  }**/

}