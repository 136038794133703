<div class="page-title-overlap stylish-color-dark pt-5 posi">
  <div class="container d-lg-flex justify-content-between">
    <div class="order-lg-2 mb-2 mb-lg-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home"><i class="fa fa-home"></i> {{"pages.breadcrumb.home" | translate }}  </a>
          </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page"> {{"pages.breadcrumb.healthcareProfessional" | translate }} </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page"> {{"pages.breadcrumb.joinNetworkCare" | translate }} </li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <!-- <h1 class="h3 text-light mb-0">Adhésion</h1> -->
    </div>
  </div>
</div>

<section class="view intro-2 py-4">
  <div class="col-10 offset-1" *ngIf="showDescription">
    <div class=" container jumbotron">
      <h1 class="display-4 h1"> {{"pages.adhere.adhereTitle" | translate}} </h1>
      <p class="lead h3"> {{"pages.adhere.joinOpenTo" | translate}} </p>
      <hr class="my-4">
      <p>
        1- {{"pages.adhere.joinOpenTo1" | translate}}
      </p>
      <p>
        2- {{"pages.adhere.joinOpenTo2" | translate}}
      </p>

      <div class="text-center btn-lg lead mt-5 pb-5">
        <button mat-raised-button color="primary" (click)="canShowDescription(false)">
          {{"pages.adhere.continue" | translate}} <i class="fa fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>

    <div class="text-center mt-3 pb-2">

    </div>
  </div>
  <div class="container mt-5 pb-5" *ngIf="!showDescription">
    <h4 class="text-center size-23"> {{"pages.adhere.formContact.contactFormMessage" | translate}} </h4>
    <mat-vertical-stepper [linear]="isLinear">
      <mat-step [stepControl]="form_adhere" label= "{{ 'pages.adhere.formContact.structureInformation.titleStructure' | translate }}">
        <form [formGroup]="form_adhere" class="example-form">
          <!-- <h2 class="text-center size-25">Formulaire d'adhésion au réseau de soins </h2> -->

          <mat-form-field class="example-full-width">

            <mat-label> {{ "pages.adhere.formContact.structureInformation.inputStructure" | translate }} </mat-label>
            <mat-select name="strucCtrl" formControlName="strucCtrl" required>
              <mat-option *ngFor="let he of structure" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field class="example-full-width ">
            <input matInput placeholder="{{ 'pages.adhere.formContact.structureInformation.inputDenomination' | translate }}" name="denomCtrl" formControlName="denomCtrl" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{ 'pages.adhere.formContact.structureInformation.registerNumber' | translate }}" name="rccm" formControlName="rccmCtrl" required>
          </mat-form-field>

          <!-- <mat-form-field class="example-full-width">
          <input matInput placeholder="Sous le numéro " name="nulber_rccm" formControlName="number_rccmCtrl" required>
          </mat-form-field> -->
          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{ 'pages.adhere.formContact.structureInformation.adress' | translate }}" name="address" formControlName="address" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput type="number" placeholder="{{ 'pages.adhere.formContact.structureInformation.companyPhoneNumber' | translate }}" name="contact"
              formControlName="contact" minlength="9" maxlength="12" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>{{ "pages.adhere.formContact.structureInformation.partnershipWith" | translate }} </mat-label>
            <!-- <mat-select required name="partenatCtrl" formControlName="partenatCtrl">
              <mat-option *ngFor="let he of insurance" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select> -->

            <mat-select multiple name="partenatCtrl" formControlName="partenatCtrl">
              <mat-option *ngFor="let he of insurance" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="example-full-width">
            <mat-checkbox name="addOther" (change)="onAddPartner($event)" [(checked)]="otherPart"> {{ "pages.adhere.formContact.structureInformation.otherPartnersSpecify" | translate }} :</mat-checkbox>
            <input matInput placeholder="précisez les en les séparant par des virgules ' , ' " name="other"
              formControlName="otherPartnersCtrl" *ngIf="otherPart">
            <hr>
          </div>

          <div class="mt-2">
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()"
              matStepperNext>{{ "pages.adhere.formContact.structureInformation.next" | translate }}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="representative_form" label="{{'pages.adhere.formContact.personalInformation.title' | translate }}">
        <form [formGroup]="representative_form" class="example-form">
          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{'pages.adhere.formContact.personalInformation.lastName' | translate }}" name="firstName" formControlName="firstCtrl" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{'pages.adhere.formContact.personalInformation.firstName' | translate }}" name="lastName" formControlName="lastCtrl" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="number" placeholder="{{'pages.adhere.formContact.personalInformation.phoneNumber' | translate }}" name="phone" formControlName="phone"
              minlength="9" maxlength="12" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{'pages.adhere.formContact.personalInformation.email' | translate }}" name="email" formControlName="email"
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="{{'pages.adhere.formContact.personalInformation.actingAs' | translate }} " name="aggissant" formControlName="aggissant" required>
          </mat-form-field>

          <mat-checkbox name="seen" [(checked)]="seen" (change)="onCheckboxChange($event)"> <a routerLink="/conditions"
              target="_blank" class="white-t"> {{"pages.adhere.formContact.personalInformation.acceptConditions" | translate }}</a></mat-checkbox>

          <div class="mt-3 mb-3">
            <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()"
              matStepperPrevious>{{"pages.adhere.formContact.personalInformation.previous" | translate }}</button>
            <button mat-raised-button color="primary"
              [disabled]="!form_adhere.valid || !representative_form.valid || !seen"
              (click)="saveAdderer()">{{"pages.adhere.formContact.personalInformation.send" | translate }}</button>

          </div>

        </form>
      </mat-step>
    </mat-vertical-stepper>

  </div>
</section>