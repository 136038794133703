import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  preferedLanguage: string="Français";
  currency: string = "GNF";
  websiteTitle: string = "Teliya santé";
  address: string = "Immeuble Maladho, 1er étage, 3ème Avenue Sandervalia, Commune de Kaloum, Conakry | B.P. 200 Conakry";

  // host2:string = "https://sante.teliya.net/gn/backend/public/api/"; //prod
  // urlCg:string = "https://prod.teliyasante.net/api/"; //prod
  //host:string = "https://teliya.net/backend/public/api/"; //prod
  // eco_visa_notif_url = "https://teliya.net/backend/public/api/visanotif"; //prod

  //host2:string ="http://localhost:8001/api/";//dev
  // host:string ="http://localhost:8000/api/";//dev
  // eco_visa_notif_url = "http://192.168.100.240:8000/api/visanotif"//dev

  host2:string ="http://devtest.teliyasante.net/api/";//devtest
  host:string ="https://testwebsite.teliya.net/backend/public/api/";//devtest
  eco_visa_notif_url = "https://testwebsite.teliya.net/backend/public/api/visanotif";//devtest
  urlCg:string = "https://sante.teliya.net/cg/backend/public/api/"; //prod

    // showLoading
  showLoad$ = new BehaviorSubject<boolean>(false);
  showLoad = this.showLoad$.asObservable();

  isAuth$ = new BehaviorSubject<boolean>(false);
  isAuth = this.isAuth$.asObservable();
  jwt: string = '';
  connectedUser: User;

  myAPIKey = '4463e36457174c2eb653d402cce6c7e5';
  mapStyle = 'https://maps.geoapify.com/v1/styles/osm-carto/style.json';
  mapAttribution = 'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>';

  geomapAccessToken = 'pk.eyJ1IjoidG91cmVwbHVzIiwiYSI6ImNrZzZwd3FtZTBuaG0yeXIwZDhxd3lwcWoifQ.z7fm2owccdLcCXj7NjIHOw';


  constructor(
    public spinner: NgxSpinnerService,
    private titleService: Title
  ){}
  loardJwtToken() {
    this.jwt = localStorage.getItem('token');
  }

  showLoading() {
    this.showLoad$.next(true);
  }

  hideLoading() {
    this.showLoad$.next(false);
  }

  fixDisplay(){
    window.dispatchEvent(new Event('resize'));
    window.scroll(0,0);
  }
  changePreferedLanguage(prefered){
    this.preferedLanguage = prefered;
    console.log(this.preferedLanguage);

  }
  showNgxLoader(){
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },60000);
  }

  public calculateAge(birthdate: any){
    const y = moment().diff(birthdate, 'years');
    if (y == 1) {
      // return y + ' an';
      return y;
    } else if (y > 1) {
      // return y + ' ans';
      return y;
    } else {
      let m = 0;
      // ((moment().diff(birthdate, 'months')) > 0 ? moment().diff(birthdate, 'months') + ' mois' :
      //  (moment().diff(birthdate, 'day') > 0 ? (moment().diff(birthdate, 'day') + ' jours') :
      //  ' ' ));
      return m;
    }
  }

  // Set Title
  setTitle(newTitle: string) {
    this.titleService.setTitle(this.websiteTitle+" | "+newTitle);
  }

}
