<div class="glob">
  <!-- <section class="view intro-2 posi">
    <div class="mask rgba-gradient">
      <div class="container h-100 d-flex justify-content-center align-items-center">
        <div class="row flex-center pt-3 mt-3">
          <div class="col-md-6 text-center text-md-left margins">
            <div class="white-text">
              <h1 class="h1-responsive font-weight-bold wow fadeInLeft text-white text-center" data-wow-delay="0.3s"
                style="font-size:4.5rem; line-height: 1em;">Teliya santé</h1>
              <br>
              <div class="text-center">
                <hr class="wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">GERER LE TIERS PAYANT</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">ELARGIR SON PORTEFEUILLE DE PARTENAIRE</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">CREER DE NOUVEAUX REVENUS POUR VOTRE STRUCTURE</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">AMELIORER LA RAPIDITE DE PRISE EN CHARGE</h3>
              </div>
              <br>
            </div>
          </div>
          <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">
            <img src="../../../../assets/img/professionne-santel.png" height="500" width="383" alt=""
              class="img-responsive">
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div class="posi pl-2 pr-2" style=" background: url(../../../../assets/img/row-bgimage-8.jpg);
                background-repeat: no-repeat; background-position:  center center;
                 background-size: cover;"
  >
    <div class="mask rgba-gradient">
      <div class="container h-100 d-flex justify-content-center align-items-center">
        <div class="row flex-center pt-3 mt-3">
          <div class="col-md-6 text-center text-md-left margins">
            <div class="white-text">
              <h1 class="h1-responsive font-weight-bold wow fadeInLeft text-white text-center" data-wow-delay="0.3s"
                style="font-size:4.5rem; line-height: 1em;">{{ "pages.healthcareNetwork.introSection.title" | translate }}</h1>
              <br>
              <div class="text-center">
                <hr class="wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white"> {{ "pages.healthcareNetwork.introSection.features.feature1" | translate }} </h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">{{ "pages.healthcareNetwork.introSection.features.feature2" | translate }}</h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">{{ "pages.healthcareNetwork.introSection.features.feature3" | translate }}</h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">{{ "pages.healthcareNetwork.introSection.features.feature4" | translate }}</h4>
              </div>
              <br>
            </div>
          </div>
          <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">
            <img src="../../../../assets/img/professionne-santel.png" height="500" width="383" alt=""
              class="img-responsive">
          </div>
        </div>
      </div>
    </div>
  </div>


  <section class="backcolor">
    <div>
      <!--Section: Features v.2-->
      <div>

        <div class=" row">

          <div class="col-10 offset-1">
            <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;"> {{ "pages.healthcareNetwork.networkSection.title" | translate }} </h1>

            <p class=" text-center mt-3 size-20" style="line-height: 25px;">
              {{ "pages.healthcareNetwork.networkSection.description" | translate }}
            </p>
            <p class="text-center">
              <a routerLink="/adhere" class="btn btn-success change" role="button"> {{ "pages.healthcareNetwork.networkSection.buttonTextLink" | translate }} </a>
            </p>
          </div>
        </div>
      </div>
      <section class="container" style="background-color: #f8f9fb;">
        <h2 class="text-center size-30" style="font-weight: bold;">   {{ "pages.healthcareNetwork.advantagesSection.title" | translate }}</h2>
        <p class="text-center" style="font-size: 24px;"> {{ "pages.healthcareNetwork.advantagesSection.subtitle" | translate }} </p>


        <div class="row text-center mb-3">
          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/ame.png" alt="">

            <h2 class="size-20">{{ "pages.healthcareNetwork.advantagesSection.features.0.title" | translate }}</h2>
            <!-- <p class="text-center size-18">Accessible sur tous les supports (tablettes, smartphone, PC) et pour toute votre équipe</p> -->



          </div><!-- /.col-lg-4 -->

          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/dev-partenaire.png"
              alt="">


            <h2 class="size-20">{{ "pages.healthcareNetwork.advantagesSection.features.1.title" | translate }}</h2>
            <!-- <p class="size-18">La plateforme est sécurisée à l'aide du SSL</p> -->

          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->


        <div class="row text-center">
          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/revenu.png" alt="">

            <h2 class="size-20">{{ "pages.healthcareNetwork.advantagesSection.features.2.title" | translate }}</h2>
            <!-- <p class="size-18">Le Backoffice est accessible pour toute votre <br> équipe sur n'importe quel ordinateur.</p> -->



          </div><!-- /.col-lg-4 -->

          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="240" height="140" src="../../../../assets/img/+outils.png" alt="">


            <h2 class="size-20">{{ "pages.healthcareNetwork.advantagesSection.features.3.title" | translate }}</h2>
            <!-- <p class="size-18">Une équipe spécialisée à votre disposition</p> -->

          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->
      </section>
      <section class="backcolor">
        <div>
          <!--Section: Features v.2-->
          <div class="ml-2 mr-2">

            <div class="row">

              <div class="col-12">
                <p class=" text-center mt-3 mb-3 size-20" style="line-height: 25px;">
                  {{ "pages.healthcareNetwork.adhesionSection.title" | translate }}
                  {{ "pages.healthcareNetwork.adhesionSection.description1" | translate }} <a routerLink="/adhere" style="color: #3db549;">{{ "pages.healthcareNetwork.adhesionSection.descriptionLink" | translate }}</a>.
                  {{ "pages.healthcareNetwork.adhesionSection.description2" | translate }}
                </p>

              </div>
            </div>
          </div>

        </div>

      </section>
      <div style="background-image: url(../../../../assets/img/footer-bg.jpg); color: #f8f9fb;">
        <div class="container pt-2">
          <div>
            <p class="size-30 text-center m-5" style="font-weight: bold; line-height: 1.2em;">
              {{ "pages.healthcareNetwork.advantagesSectionExtend.title" | translate }}  
            </p>
          </div>



            <div class="mt-5 mb-5 text-center">
            <p class="mt-2 mb-5 container text-center size-18">
              {{ "pages.healthcareNetwork.advantagesSectionExtend.description" | translate }}
            </p>

            <div class="row mb-5">
              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/recoverate.svg" alt=""></span>
                <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.0.title" | translate }}</h2>
                <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.0.description" | translate }}
                </p>

              </div>

              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/reduce.svg"
                    alt=""></span>
                <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.1.title" | translate }}</h2>
                <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.1.description" | translate }}</p>

              </div>
              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/features_statistics.svg" alt=""></span>
                    <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.2.title" | translate }}</h2>
                    <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.2.description" | translate }}</p>

              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/features_monitoring.svg" alt=""></span>
                    <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.3.title" | translate }}</h2>
                    <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.3.description" | translate }}</p>
              </div>
              <div class="col-md-4">

                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/simplification.svg" alt=""></span>
                    <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.4.title" | translate }}</h2>
                    <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.4.description" | translate }}</p>
              </div>
              <div class="col-md-4">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/acceleration.svg" alt=""></span>
                    <h2>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.5.title" | translate }}</h2>
                    <p>{{ "pages.healthcareNetwork.advantagesSectionExtend.features.5.description" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>



  <section class="backcolory">
    <div class="container">
      <div class="section-working text-center pl-3">
        <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;">
          {{ "pages.healthcareNetwork.tiersPayantSection.title" | translate }}
        </h1>

        <p class=" text-center mt-3 size-20" style="line-height: 25px;">
          {{ "pages.healthcareNetwork.tiersPayantSection.description" | translate }}
        </p>
        <!-- <h2 class="size-30">Teliya santé, comment ça marche ?</h2> -->

        <div class="row">

          <div class="col-4 details jumbotron">
            <h3 class="text-left size-20 font-weight-bold text-center"> {{ "pages.healthcareNetwork.tiersPayantSection.steps.0.title" | translate }}</h3>
            <ul class="list-group size-16">
              <li class="list-group-item">
                <span class="badge bg-success">1</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.0.details.0" | translate }}
              </li>
              <li class="list-group-item">
                <span class="badge bg-success">2</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.0.details.1" | translate }}
              </li>
              <li class="list-group-item">
                <span class="badge bg-success">3</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.0.details.2" | translate }}
              </li>
            </ul>
          </div>

          <div class="col-3 details jumbotron ">
            <h3 class="text-left size-20 font-weight-bold text-center"> {{ "pages.healthcareNetwork.tiersPayantSection.steps.1.title" | translate }}</h3>
            <ul class="list-group size-16">
              <li class="list-group-item">
                <span class="badge bg-success">1</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.1.details.0" | translate }}
              </li>
              <li class="list-group-item">
                <span class="badge bg-success">2</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.1.details.1" | translate }}
              </li>
              <li class="list-group-item">
                <span class="badge bg-success">3</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.1.details.2" | translate }}
              </li>
            </ul>
          </div>

          <div class="col-4 details jumbotron">
            <h3 class="text-left size-20 font-weight-bold text-center"> {{ "pages.healthcareNetwork.tiersPayantSection.steps.2.title" | translate }}</h3>
            <ul class="list-group size-16">
              <li class="list-group-item">
                <span class="badge bg-success">1</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.2.details.0" | translate }}
              </li>
              <li class="list-group-item">
                <span class="badge bg-success">2</span> {{ "pages.healthcareNetwork.tiersPayantSection.steps.2.details.1" | translate }}
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div class="text-center">
        <h2 class="size-30 pt-3" style="font-weight: bold;"> {{ "pages.healthcareNetwork.deploymentSection.title" | translate }} </h2>
        <p class="size-20">{{ "pages.healthcareNetwork.deploymentSection.subtitle" | translate }}</p>
      </div>
      <div class="row text-center justify-content-center align-items-center">
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement1.png" class="bd-placeholder-img" width="120" height="110" alt="">


          <p class="size-18">{{ "pages.healthcareNetwork.deploymentSection.steps.0.title" | translate }}</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement2.png" class="bd-placeholder-img " width="120" height="110"
            alt="">


          <p class="size-18">{{ "pages.healthcareNetwork.deploymentSection.steps.1.title" | translate }}</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement3.png" class="bd-placeholder-img" width="120" height="110" alt="">


          <p class="size-18">{{ "pages.healthcareNetwork.deploymentSection.steps.2.title" | translate }}</p>

        </div><!-- /.col-lg-4 -->
        <a routerLink="/adhere" class="btn btn-success p-3 m-3" role="button">{{ "pages.healthcareNetwork.buttonTextLink" | translate }}</a>
      </div><!-- /.row -->
      <!-- <div class=" row pb-3">

        <div class="col-10 offset-1 ">
          <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;">NOS MEMBRES</h1>

          <p class=" text-center mt-3 size-22" style="line-height: 25px;">
            Retrouvez l'ensemble du réseau de soins Teliya
          </p>

        </div>
        <div class="col-4 offset-4 " style="background-color: #033B55;">
          <ngb-carousel class="mt-3 mb-3">
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="../../../../assets/img/deploiement1.png" alt="Angular Carousel 1">
              </div>
              <div class="carousel-caption">
                <h3>NSIA ASSURANCE</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="../../../../assets/img/deploiement3.png" alt="Angular Carousel 2">
              </div>
              <div class="carousel-caption">
                <h3>SOGAM</h3>
              </div>
            </ng-template>
          </ngb-carousel>

        </div>
      </div> -->
    </div>

  </section>

</div>
