<div class="ml-2 mr-2 pt-2 pb-2">
    <h2 mat-dialog-title class="text-center">{{"pages.conditionsGenerales.title" | translate }}</h2>
    <div mat-dialog-content>

        <p>
            {{"pages.conditionsGenerales.description" | translate }}
        </p>
        <p>
            <strong><u>{{"pages.conditionsGenerales.content.0.title" | translate }}</u></strong>
            {{"pages.conditionsGenerales.content.0.description" | translate }}
        </p>
        <p>
            <strong><u>{{"pages.conditionsGenerales.content.1.title" | translate }}</u></strong>
            {{"pages.conditionsGenerales.content.1.description" | translate }}
        </p>
        <p>
            <strong><u>{{"pages.conditionsGenerales.content.2.title" | translate }}</u></strong>
            {{"pages.conditionsGenerales.content.2.description" | translate }}
        </p>
        <p>
            <strong><u>{{"pages.conditionsGenerales.content.3.title" | translate }}</u></strong>
            {{"pages.conditionsGenerales.content.3.description" | translate }}        </p>
        <p>
            <strong><u>{{"pages.conditionsGenerales.content.4.title" | translate }}</u></strong>
            {{"pages.conditionsGenerales.content.4.description" | translate }}        </p>
        <p>
            {{"pages.conditionsGenerales.content.5.description" | translate }}        </p>
        <p>
            {{"pages.conditionsGenerales.content.6.description" | translate }}        </p>
        <p>
            {{"pages.conditionsGenerales.content.7.description" | translate }}        </p>
        <p>
            {{"pages.conditionsGenerales.content.8.description" | translate }}        </p>
        <p>
            {{"pages.conditionsGenerales.content.9.description" | translate }}        </p>
        <p>
            <strong>{{"pages.conditionsGenerales.content.10.description" | translate }}</strong>
        </p>
    </div>
    <div mat-dialog-actions align="end">
        <button class="mat-raised-button" color="accent" (click)="close()">{{"pages.conditionsGenerales.buttons.close" | translate }}</button>
    </div>
</div>
